import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    titleCellContent: {
        display: "flex",
        alignItems: "center",
    },
    tableHead: {},
    tableRow: {
        "&:hover": {
            backgroundColor: theme.palette.color1,
        },
    },
    headTableCell: {
        padding: "5px 10px",
        borderRight: `1px solid ${theme.palette.color3}`,
        "&:last-of-type": {
            borderRight: "none",
        },
    },
    tableCellActions: {
        width: "1%",
        padding: "5px!important",
        textAlign: "center",
    },
    tableCell: {
        minWidth: "0px",
        maxWidth: "200px",
        padding: "5px 10px",
        borderRight: `1px solid ${theme.palette.color3}`,
        wordBreak: "break-word",
        "&:last-child": {
            borderRight: "none",
        },
    },
    tableCellCheckbox: {
        width: "1%",
        padding: "0px",
    },
    tableCellUuid: {
        width: "100px",
        fontSize: "10px",
        wordBreak: "break-word",
    },
    sortContainer: {
        marginLeft: "2px",
        display: "inline-flex",
    },
    sortIconButton: {
        padding: "5px",
        color: "#000",
    },
    sortConnectedArrows: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    sortConnectedArrowsInitIcon: {
        opacity: 0,
    },
    sortConnectedArrowsUpIcon: {
        position: "absolute",
        top: 0,
    },
    sortConnectedArrowsDownIcon: {
        position: "absolute",
        bottom: 0,
    },
    imgPreviewContainer: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    imgPreview: {
        maxWidth: "75px",
        marginRight: "15px",
        display: "inline-block",
    },
    imgPreviewTitle: {
        display: "inline-block",
        color: "#000",
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
    },
}));

export default useStyles;
