import React from 'react';
import {useDropzone} from "react-dropzone";
import {makeStyles} from "@mui/styles";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import clsx from "clsx";
import {Button} from "@mui/material";
import {useMount} from "@s-ui/react-hooks";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";

const UploadInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props)

    useMount(() => {
        setGlobalFormFieldValue(inputName, inputValue, props);
    })

    const useStyles = makeStyles(theme => ({
        dropzoneContainer: {},
        dropArea: {
            padding: "10px",
            border: `1px dashed ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.color1,
            textAlign: "center",
            outline: "none",
            cursor: "pointer",
            lineHeight: 2,
            fontSize: "14px",
        },
        filesContainer: {
            margin: "0 0 15px 0",
        },
        label: {
            color: "#000",
            margin: "0 0 5px 0",
        },
        details: {
            margin: 0,
            padding: 0,
        },
        detailsItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: `1px solid ${theme.palette.color4}`,
            padding: "5px",
            listStyleType: "none",
            wordBreak: "break-word",
            "& a": {
                color: "#1DA2E0",
                textDecoration: "underline",
            }
        },
        imagePreviewContainer: {
            marginRight: "5px",
            border: `1px solid ${theme.palette.color4}`,
        },
        imagePreview: {
            maxWidth: "150px",
        },
        filesTitle: {
            fontWeight: 700,
        },
        filesTitleNoFiles: {
            color: theme.palette.color7,
        },
        filesTitleActual: {
            color: "#000",
        },
        filesTitleAdded: {
            color: theme.palette.color2,
        },
        filesTitleRejected: {
            color: theme.palette.color7,
        },
        filesList: {
            wordBreak: "break-word",
            margin: 0,
            padding: 0,
        },
        deleteFileBtn: {
            backgroundColor: theme.palette.color7,
            color: "#fff",
            marginLeft: "5px",
            "&:hover": {
                backgroundColor: theme.palette.color7,
                color: "#fff",
            }
        },
    }));

    const classes = useStyles();

    let {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles: 1, //do not change, api supports only one file per input, onDrop is adapted to this format
        accept: props.field.options.allowedExtensions.map(ext => (`.${ext}`)).join(", "),
        onDropAccepted: (acceptedFiles) => {
            const file = acceptedFiles[0];

            const reader = new FileReader();

            reader.onload = (event) => {
                const value = {
                    delete: false,
                    path: file.path,
                    base64: event.target.result,
                }

                setGlobalFormFieldValue(inputName, value, props);
            };

            reader.readAsDataURL(file);
        },
        onDropRejected: () => {
            const isActuallyAddedFile = inputValue?.base64;

            if (isActuallyAddedFile) {
                setGlobalFormFieldValue(inputName, {
                    delete: true,
                }, props);
            }
        },
    });

    const deleteFile = () => {
        setGlobalFormFieldValue(inputName, {
            delete: true,
        }, props);
        acceptedFiles = acceptedFiles.splice(0, acceptedFiles.length);
    }

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path} className={classes.detailsItem}>
            {file.path} - {Math.ceil(file.size / 1024).toFixed(2)} kB

            <Button size={"small"} className={classes.deleteFileBtn} onClick={() => deleteFile()}>
                Usuń
            </Button>
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={file.path} className={classes.detailsItem}>
            {file.path} - {(file.size / 1024).toFixed(2)} kB
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    const getImageExtension = (filename) => {
        return filename.split('.').pop();
    }

    const isImage = () => {
        const filename = inputValue?.preview;

        const extension = getImageExtension(filename);

        return imageExtensions.includes(extension);
    }

    return (
        <OutlinedDiv label={createInputLabel(props.field.label, props.field.required)}>
            <div className={classes.dropzoneContainer}>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} name={inputName} multiple={false}/>
                    <p className={classes.dropArea}>
                        {(inputValue?.uuid || inputValue?.path) && (
                            <><b>UWAGA: Aktualny plik zostanie nadpisany</b><br/></>
                        )}
                        Dodaj <b>1 plik</b><br/>
                        Akceptowane typy plików: <b>{props.field.options.allowedExtensions.join(", ")}</b><br/>
                        Przeciągnij lub kliknij, aby wybrać ręcznie<br/>
                    </p>
                </div>

                {fileRejectionItems?.length > 0 && (
                    <div className={classes.filesContainer}>
                        <div className={clsx(classes.filesTitle, classes.filesTitleActual)}>
                            Plik został odrzucony
                        </div>

                        <ul className={classes.details}>{fileRejectionItems}</ul>
                    </div>
                )}

                {acceptedFileItems?.length > 0 && (
                    <div className={classes.filesContainer}>
                        <div className={clsx(classes.filesTitle, classes.filesTitleActual)}>
                            Nowy plik
                        </div>

                        <ul className={classes.details}>{acceptedFileItems}</ul>
                    </div>
                )}


                {inputValue?.uuid && (
                    <div className={classes.filesContainer}>
                        <div className={clsx(classes.filesTitle, classes.filesTitleActual)}>
                            Aktualny plik
                        </div>
                        <ul className={classes.details}>
                            <li className={classes.detailsItem}>
                                {isImage() && (
                                    <div className={classes.imagePreviewContainer}>
                                        <a href={inputValue?.preview} target="_blank" rel="noreferrer">
                                            <img
                                                src={inputValue.preview}
                                                className={classes.imagePreview}
                                                alt={"Img"}
                                            />
                                        </a>
                                    </div>
                                )}
                                <a href={inputValue?.preview} target="_blank" rel="noreferrer">
                                    {inputValue?.preview}
                                </a>
                                <Button size={"small"} className={classes.deleteFileBtn} onClick={() => deleteFile()}>
                                    Usuń
                                </Button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </OutlinedDiv>
    );
}

export default UploadInput;
