import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Show from "@components/views/ApiBuilder/views/Show/Main";

const ShowContainer = (props) => {
    const {apiBuilder} = props;

    return (
        <Show config={apiBuilder.view.viewData.data}/>
    );
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {},
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShowContainer);