import React from 'react';
import HeaderActions from "./Main";
import {bindActionCreators} from "redux";
import {sendApiAction, sendApiActionInit} from "@store/actions/apiBuilder/sendApiAction";
import {connect} from "react-redux";

const HeaderActionsContainer = (props) => {
    return (
        <HeaderActions {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                sendApiActionInit,
                sendApiAction,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderActionsContainer);