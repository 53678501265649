import {
    GET_API_VIEW_INIT,
    GET_API_VIEW_SUCCESS,
    GET_API_VIEW_FAILURE, SET_SOURCE_REDIRECT_LIST_URL,
} from "@store/types/apiBuilder";

import {client} from "@api/client";
import {getAccessToken} from "@tools/tokens";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";
import {scrollToElement} from "@tools/scroll";

const getViewInit = () => ({
    type: GET_API_VIEW_INIT,
});

const getViewSuccess = (data) => ({
    type: GET_API_VIEW_SUCCESS,
    payload: data
});

const getViewFailure = (errors) => ({
    type: GET_API_VIEW_FAILURE,
    payload: errors
});

const setSourceRedirectListUrl = (data) => ({
    type: SET_SOURCE_REDIRECT_LIST_URL,
    payload: data
});

const getView = (url) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.GET_VIEW));

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client("GET", url, null, headers);
            const responseData = response.data.data;

            await dispatch(getViewInit());
            await dispatch(getViewSuccess(responseData));

            scrollToElement();
        } catch (error) {
            if (error.response) {
                await dispatch(getViewInit());
                await dispatch(getViewFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.GET_VIEW));
    }
}

export {
    getViewInit,
    getView,
    setSourceRedirectListUrl
};