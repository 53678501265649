import queryString from "querystring";

export const scrollToElement = () => {
    const parsedSearch = queryString.parse(window.location.search);
    const scrollToParam = parsedSearch.scrollTo;
    if (scrollToParam) {
        const element = document.getElementById(`${scrollToParam}`);
        if (element) {
            window.scroll({
                top: element.offsetTop,
                behavior: 'smooth'
            });
        }
    } else {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
}