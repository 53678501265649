import React, {useState} from 'react';
import {FormControl, InputLabel, Select, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

const SelectInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const handleChangeSelect = (value) => {
        value = (value === "not-selected") ? "" : value;

        setValue(value);
        setGlobalFormFieldValue(inputName, value, props);
    }

    const transformSelectedValue = () => {
        let value = inputValue;

        return value ? value : "not-selected";
    }

    const classes = useStyles();

    return (
        <>
            <FormControl variant={"outlined"} size={"small"} fullWidth>
                <InputLabel htmlFor={props.field.name}>
                    {createInputLabel(props.field.label, props.field.required)}
                </InputLabel>
                <Select
                    native
                    disabled={props.field.options.disabled}
                    value={transformSelectedValue()}
                    onChange={event => handleChangeSelect(event.target.value)}
                    label={createInputLabel(props.field.label, props.field.required)}
                    color={"primary"}
                    inputProps={{
                        name: inputName,
                        id: inputName,
                    }}
                >
                    <option value="not-selected">Wybierz</option>
                    {props.field.choices.map(choice => (
                        <option value={choice.value} key={choice.value}>{choice.name}</option>
                    ))}
                </Select>
            </FormControl>

            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue("");
                            setGlobalFormFieldValue(inputName, "", props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default SelectInput;