import React from 'react';
import {createInputName, getGlobalFormFieldValue, setGlobalFormFieldValue} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";

const HiddenInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    useMount(() => {
        setGlobalFormFieldValue(inputName, inputValue, props);
    })

    return (
        <input
            readOnly={props.field.attributes.readonly}
            disabled={props.field.options.disabled}
            type={props.field.type}
            id={inputName}
            name={inputName}
            value={inputValue}
        />
    );
}

export default HiddenInput;