import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Tooltip} from "@mui/material";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

const TextareaInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <TextField
                multiline
                minRows={3}
                maxRows={20}
                fullWidth
                readOnly={props.field.attributes.readonly}
                disabled={props.field.options.disabled}
                type={props.field.type}
                id={inputName}
                name={inputName}
                label={createInputLabel(props.field.label, props.field.required)}
                variant="outlined"
                value={value}
                color={"primary"}
                onChange={event => setValue(event.target.value)}
                onBlur={event => setGlobalFormFieldValue(inputName, event.target.value, props)}
            />
            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue("");
                            setGlobalFormFieldValue(inputName, "", props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default TextareaInput;