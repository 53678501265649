import {
    GET_API_VIEW_INIT,
    GET_API_VIEW_SUCCESS,
    GET_API_VIEW_FAILURE,
    SEND_API_ACTION_INIT,
    SEND_API_ACTION_SUCCESS,
    SEND_API_ACTION_FAILURE, SET_SOURCE_REDIRECT_LIST_URL
} from "@store/types/apiBuilder";

const initialState = {
    viewData: null,
    viewErrors: null,

    apiActionSent: false,
    apiActionSentErrors: null,
    apiActionSentSuccessMessage: null,

    sourceRedirectListUrl: null,
}

const viewReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_API_VIEW_INIT: {
            return {
                ...initialState,
                sourceRedirectListUrl: state.sourceRedirectListUrl,
            };
        }
        case GET_API_VIEW_SUCCESS: {
            return {
                ...initialState,
                viewData: action.payload,
                viewErrors: initialState.viewErrors,

                sourceRedirectListUrl: state.sourceRedirectListUrl,
            };
        }
        case GET_API_VIEW_FAILURE: {
            return {
                ...initialState,
                viewData: initialState.viewData,
                viewErrors: action.payload,

                sourceRedirectListUrl: state.sourceRedirectListUrl,
            };
        }

        case SET_SOURCE_REDIRECT_LIST_URL: {
            return {
                ...state,
                sourceRedirectListUrl: action.payload,
            };
        }

        case SEND_API_ACTION_INIT: {
            return {
                ...state,
                apiActionSent: initialState.apiActionSent,
                apiActionSentErrors: initialState.apiActionSentErrors,
                apiActionSentSuccessMessage: initialState.apiActionSentSuccessMessage,
            };
        }
        case SEND_API_ACTION_SUCCESS: {
            return {
                ...state,
                apiActionSent: true,
                apiActionSentErrors: initialState.apiActionSentErrors,
                apiActionSentSuccessMessage: action.payload,
            };
        }
        case SEND_API_ACTION_FAILURE: {
            return {
                ...state,
                apiActionSent: false,
                apiActionSentErrors: action.payload,
                apiActionSentSuccessMessage: initialState.apiActionSentSuccessMessage,
            };
        }

        default:
            return state;
    }
}

export default viewReducer;