const BASIC_FORM_FIELDS_CONFIG = {
    LOGIN: [
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            type: 'email',
        },
        {
            name: 'password',
            value: '',
            label: 'Hasło',
            type: 'password',
        }
    ],
    RECOVER_PASSWORD: [
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            type: 'email',
        }
    ],
    RESET_PASSWORD: [
        {
            name: 'password',
            value: '',
            label: 'Hasło',
            type: 'password',
        },
        {
            name: 'confirmPassword',
            value: '',
            label: 'Powtórz hasło',
            type: 'password',
        },
    ],
    USER_PROFILE: {
        USER_DATA: [
            {
                name: 'firstName',
                value: '',
                label: 'Imię',
                type: 'text',
            },
            {
                name: 'lastName',
                value: '',
                label: 'Nazwisko',
                type: 'text',
            },
            {
                name: 'phone',
                value: '',
                label: 'Nr telefonu',
                type: 'number',
            },
            {
                name: 'email',
                value: '',
                label: 'Adres email (tego pola nie można zmieniać)',
                type: 'email',
            },
        ],
        CHANGE_PASSWORD: [
            {
                name: 'currentPassword',
                value: '',
                label: 'Aktualne hasło',
                type: 'password',
            },
            {
                name: 'password',
                value: '',
                label: 'Nowe hasło',
                type: 'password',
            },
        ],
    },
}

export default BASIC_FORM_FIELDS_CONFIG;