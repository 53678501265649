import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    box: {
        "&:first-of-type": {
            marginTop: 0,
        }
    },
    title: {
        fontSize: "16px",
        fontWeight: 700,
        marginTop: "15px",
    },
    description: {
        fontSize: "14px",
    },
}));

export default useStyles;