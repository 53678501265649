import {
    SEND_API_ACTION_INIT,
    SEND_API_ACTION_SUCCESS,
    SEND_API_ACTION_FAILURE,
} from "@store/types/apiBuilder";

import {client} from "@api/client";
import {getAccessToken} from "@tools/tokens";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendApiActionInit = () => ({
    type: SEND_API_ACTION_INIT,
});

const sendApiActionSuccess = (successMessage) => ({
    type: SEND_API_ACTION_SUCCESS,
    payload: successMessage
});

const sendApiActionFailure = (errors) => ({
    type: SEND_API_ACTION_FAILURE,
    payload: errors
});

const sendApiAction = (method, action, data, successMessage) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.SEND_API_ACTION));
        await dispatch(sendApiActionInit());

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            await client(method, action, data, headers);

            await dispatch(sendApiActionSuccess(successMessage));
        } catch (error) {
            if (error.response) {
                await dispatch(sendApiActionFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.SEND_API_ACTION));
    }
}

export {
    sendApiActionInit,
    sendApiAction,
};