import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    paginationContainer: {
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.color1,
        boxShadow: `0 0 5px ${theme.palette.color4}`,
    },
    refreshLink: {
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    refreshBtn: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
}));

export default useStyles;