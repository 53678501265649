import React from 'react';
import useStyles from "../styles";

const ImageComponent = (props) => {
    const classes = useStyles();

    return (
        <>
            <p>{props.component.data.title}</p>
            <a href={props.component.data.src} target={"_blank"} rel="noreferrer" title={"Kliknij, aby zobaczyć w pełnym rozmiarze"}>
                <img src={props.component.data.src} alt={props.component.data.title} className={classes.image}/>
            </a>
        </>
    );
}

export default ImageComponent;
