import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {ErrorOutline} from "@mui/icons-material";
import useStyles from "./styles";

const BasicFormBuilder = (props) => {
    const [fields, setFields] = useState(props.fields);
    const {errors, btnText} = props;
    const classes = useStyles();

    const handleChange = (name, value) => {
        const updatedFields = fields.map((field) => {
            if (field.name === name) {
                return {
                    ...field,
                    value: value,
                };
            }

            return field;
        });

        setFields(updatedFields);
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = {};

        fields.forEach((field) => {
            data[field.name] = field.value;
        });

        props.handleFormValid(data);
    }

    const getErrorMessage = (name) => {
        return errors?.filter((error => error.context === name)).map((type, index) => (
            <div className={classes.fieldError} key={index}>
                <ErrorOutline/> {type.message}
            </div>
        ));
    }

    const getUnrecognizedErrorMessages = () => {
        const unRecognizedErrorMessages = errors?.filter(error => {
            const found = fields.find(el => el.name === error.context);

            return found ? null : error;
        });

        if (unRecognizedErrorMessages?.length) {
            return (
                <div className={classes.serverErrors}>
                    {unRecognizedErrorMessages.map((error, index) => (
                        <div className={classes.serverError} key={index}>
                            <ErrorOutline/> {error.message}
                        </div>
                    ))}
                </div>
            )
        }
    }

    return (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
            {fields.map((field, index) => (
                <div className={classes.fieldRow} key={index}>
                    <TextField
                        margin="normal"
                        fullWidth
                        required={field.isRequired}
                        autoFocus={index === 0}
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        variant="outlined"
                        value={field.value}
                        className={classes.textField}
                        onChange={event => handleChange(event.target.name, event.target.value)}
                    />

                    {getErrorMessage(field.name)}
                </div>
            ))}

            {getUnrecognizedErrorMessages()}

            <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                className={classes.submitBtn}
                disableElevation
            >
                {btnText}
            </Button>
        </form>
    );
}

export default BasicFormBuilder;