import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: "65px",
        color: theme.palette.color5,
        backgroundColor: "#fff",
        boxShadow: `0 0 5px ${theme.palette.color4}`,
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    toolbarGroup: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    pageTitleContainer: {},
    pageTitleDescription: {
        fontSize: "12px",
    },
    pageTitle: {
        fontWeight: 600,
        fontSize: "14px",
    },
    menuButton: {},
    homeLink: {
        margin: "0 10px 0 0",
    },
    envInfo: {
        color: "#777",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "15px",
    },
    envInfoRed: {
        padding: "5px 15px",
        background: "#ff0000",
        border: "1px solid #ff0000",
        color: "#fff",
    },
    envInfoGreen: {
        color: "green",
    },
    userSection: {
        position: "relative",
    },
    userCircle: {
        cursor: "pointer",
        width: "35px",
        height: "35px",
        backgroundColor: theme.palette.color5,
        color: "#fff",
        fontSize: "17px",
        fontWeight: 300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
    },
    userSectionMenu: {
        position: "relative",
        right: 0,
        top: 0,
    },
    menuList: {
        padding: 0,
    },
    userInformation: {
        minWidth: "300px",
        display: "flex",
        alignItems: "center",
        padding: "15px",
    },
    userIcon: {
        width: "50px",
        height: "50px",
        color: theme.palette.primary.main,
    },
    userData: {
        paddingLeft: "10px",
    },
    userDataName: {
        fontSize: "15px",
        fontWeight: 500,
        color: "#000",
    },
    userDataEmail: {
        fontSize: "13px",
        fontWeight: 400,
        color: theme.palette.color5,
    },
    userMenuItemsGroup: {
        borderTop: `1px solid ${theme.palette.color3}`,
    },
    userMenuItem: {
        padding: "10px 30px 10px 20px",
    },
    loginBtn: {
        color: "#fff",
    },
    drawer: {},
    menu: {
        minWidth: "250px",
    },
    menuMessage: {
        padding: "15px",
    },
    menuItem: {
        width: "100%",
    },
    swapIcon: {
        width: "15px",
        height: "15px",
        margin: "0 5px 0 0",
        color: theme.palette.primary.main,
    },
    groupTitle: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 500,
        padding: "10px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        cursor: "pointer",
        margin: "0 0 5px 0",
    },
    groupTitleChild: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        lineHeight: 1.5,
        padding: "10px",
        cursor: "pointer",
        color: "#000",
        "&:hover": {
            backgroundColor: theme.palette.color1,
        }
    },
    groupList: {
        width: "100%",
        padding: 0,
        margin: 0,
        height: 0,
        overflow: "hidden",
    },
    groupListChild: {
        padding: 0,
        width: "100%",
        margin: 0,
        height: 0,
        overflow: "hidden",
    },
    groupListActive: {
        height: "auto",
    },
    listItem: {
        cursor: "default",
        width: "100%",
        padding: 0,
        margin: 0,
        position: "relative",
    },
    listItemLink: {
        fontSize: "14px",
        color: "#000",
        padding: "10px",
        margin: 0,
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.color1,
        }
    },
    listItemLinkChild: {
        fontSize: "14px",
        color: "#000",
        padding: "10px",
        margin: "0 0 0 20px",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.color1,
        }
    },
    listItemLinkActive: {
        fontWeight: 600,
    },
    listItemIcon: {
        width: "15px",
        height: "15px",
        margin: "-2px 5px 0 0",
        color: theme.palette.primary.main,
    },
}));

export default useStyles;