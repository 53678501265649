const LOADER_PLACES = {
    GET_MENU: 'GET_MENU',
    LOGIN: 'LOGIN',
    AUTOLOGIN: 'AUTOLOGIN',
    LOGOUT: 'LOGOUT',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_CHECK_TOKEN: 'RESET_PASSWORD_CHECK_TOKEN',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    GET_VIEW: 'GET_VIEW',
    GET_AUTOCOMPLETE: 'GET_AUTOCOMPLETE',
    SEND_FORM_DATA: 'SEND_FORM_DATA',
    SEND_API_ACTION: 'SEND_API_ACTION',
}

export default LOADER_PLACES;
