import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {sendApiAction, sendApiActionInit} from "@store/actions/apiBuilder/sendApiAction";
import Actions from "@components/views/ApiBuilder/views/Grid/List/Actions/Main";

const ActionsContainer = (props) => {
    return (
        <Actions {...props}/>
    )
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                sendApiActionInit,
                sendApiAction,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionsContainer);