import {
    AUTO_LOGIN_USER_SUCCESS,
    AUTO_LOGIN_USER_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader, setAppReady} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import {logout} from "@store/actions/user";
import {getMenu} from "@store/actions/user/profile/menu";
import LOADER_PLACES from "@consts/loaderPlaces";

const autoLoginSuccess = (data) => ({
    type: AUTO_LOGIN_USER_SUCCESS,
    payload: data
});

const autoLoginFailure = (errors) => ({
    type: AUTO_LOGIN_USER_FAILURE,
    payload: errors
});

const autoLogin = () => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.AUTOLOGIN));

        const path = '/api/profile/me';
        const url = (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path;

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('GET', url, null, headers);
            const responseData = response.data.data;

            const userData = responseData.data;

            await dispatch(autoLoginSuccess(userData));
            await dispatch(getMenu());
        } catch (error) {
            if (error.response) {
                await dispatch(autoLoginFailure(error.response.data.errors));
            }

            await dispatch(logout());
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.AUTOLOGIN));
        await dispatch(setAppReady());
    }
}

export default autoLogin;