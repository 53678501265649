import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700,
        marginTop: "30px",
        fontSize: "20px",
    },
    code: {},
    pre: {
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        fontSize: "14px",
    },
}));

export default useStyles;