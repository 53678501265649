import React from 'react';
import {Grid, Paper} from "@mui/material";
import { Skeleton } from '@mui/material';
import useStyles from "./styles";

const SkeletonContent = () => {
    const classes = useStyles();

    const generateRandomNumber = (min, max) => {
        return min + (Math.random() * (max - min));
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <Grid container>
                {[...Array(7)].map((el, index) => (
                    <Grid item xs={12} key={index}>
                        <Skeleton
                            variant="text"
                            width={`${generateRandomNumber(25, 100)}%`}
                            animation="wave"
                        />
                        <Skeleton
                            variant="text"
                            width={`${generateRandomNumber(25, 100)}%`}
                            animation="wave"
                        />
                        <Skeleton
                            variant="text"
                            width={`${generateRandomNumber(25, 100)}%`}
                            animation="wave"
                        />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}

export default SkeletonContent;