import React, {useState} from 'react';
import Row from "@components/views/ApiBuilder/views/shared/Row";
import {Add, Delete} from "@mui/icons-material";
import {Button, FormLabel} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import clsx from "clsx";

const CollectionInput = (props) => {
    const inputName = createInputName(props.field.name, props);

    const [localValue, setLocalValue] = useState({
        ...getGlobalFormFieldValue(inputName, props)
    });

    useMount(() => {
        setFilteredFormValue(localValue);
    });

    const setFilteredFormValue = (value) => {
        let formValues = {};
        Object.entries(value).forEach(([key, val]) => {
            if (val) {
                formValues[key] = val;
            }
        });

        setGlobalFormFieldValue(inputName, formValues, props);
    }

    const useStyles = makeStyles(theme => ({
        collectionContainer: {
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "5px",
            overflow: "hidden",
            backgroundColor: theme.palette.color1,
        },
        label: {
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
            padding: "7px 10px",
            fontSize: "15px",
        },
        contentContainer: {
            padding: "25px 20px 20px 20px",
        },
        collectionRow: {
            backgroundColor: "#fff",
            display: "table",
            width: "100%",
            padding: "30px 20px 20px 20px",
            boxShadow: `0 0 7px ${theme.palette.color4}`,
            margin: "0 0 25px 0",
            position: "relative",
            "&:hover": {
                borderColor: theme.palette.primary.main,
            },
            "&:last-of-type": {
                margin: 0,
            }
        },
        collectionIndex: {
            backgroundColor: theme.palette.color5,
            color: "#fff",
            fontSize: "13px",
            padding: "2px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: "15px",
            position: "absolute",
            borderRadius: "3px",
            transform: "translate(0, -50%)",
            fontWeight: 500,
        },
        actionRowContainer: {
            margin: "15px 0 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        actionIcon: {
            marginRight: "5px",
        },
        actionBtn: {
            padding: "7px 15px 7px 10px",
            textTransform: "none",
        },
        addActionBtn: {
            color: "#fff",
            backgroundColor: theme.palette.color2,
            "&:hover": {
                color: "#fff",
                backgroundColor: theme.palette.color2,
            },
        },
        removeActionBtn: {
            color: "#fff",
            backgroundColor: theme.palette.color7,
            "&:hover": {
                color: "#fff",
                backgroundColor: theme.palette.color7,
            },
        },
    }));

    const classes = useStyles();

    const addNewCollectionRow = () => {
        const keysLength = Object.keys(localValue).length;

        let nextIndex = 0;

        if (keysLength > 0) {
            let lastIndex = Object.keys(localValue).reduce((a, b) => a > +b ? a : +b);

            nextIndex = parseInt(lastIndex) + 1;
        }

        const newValue = {
            ...localValue,
            [nextIndex]: {}
        };

        setLocalValue(newValue);

        setFilteredFormValue(newValue);
    }

    const removeCollectionRow = async (collectionIndex) => {
        const newValue = {
            ...localValue,
            [collectionIndex]: null,
        };

        setLocalValue(newValue);

        setFilteredFormValue(newValue);
    }

    const handleChangeCollection = (key, value, collectionIndex) => {
        let newValue = localValue;

        newValue[collectionIndex][key] = value;

        setLocalValue(newValue);

        setFilteredFormValue(newValue);
    }

    return (
        <div className={classes.collectionContainer}>
            <FormLabel component="legend" className={classes.label}>
                {createInputLabel(props.field.label, props.field.required)}
            </FormLabel>

            <div className={classes.contentContainer}>
                {Object.entries(localValue).filter(([key, value]) => value).map(([key, value], index) => (
                    <div className={classes.collectionRow} key={key}>
                        <div className={classes.collectionIndex}>Element {index + 1}</div>
                        {props?.field?.prototype?.rows?.map((row, index) => (
                            <Row
                                key={index}
                                {...props}
                                row={row}
                                parentType={"collection"}
                                collectionIndex={key}
                                parentName={props.field.name}
                                handleChangeCollection={handleChangeCollection}
                            />
                        ))}
                        {props?.field?.allow_delete && (
                            <div className={classes.actionRowContainer}>
                                <Button onClick={() => removeCollectionRow(key)}
                                        className={clsx(classes.actionBtn, classes.removeActionBtn)}>
                                    <Delete className={classes.actionIcon}/> Usuń
                                </Button>
                            </div>
                        )}
                    </div>
                ))}

                {Object.entries(localValue).filter(([key, value]) => value).length === 0 && <>Brak elementów</>}

                {props?.field?.allow_add && (
                    <div className={classes.actionRowContainer}>
                        <Button onClick={() => addNewCollectionRow()}
                                className={clsx(classes.actionBtn, classes.addActionBtn)}>
                            <Add className={classes.actionIcon}/> Dodaj nowy
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CollectionInput;
