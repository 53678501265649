import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    tabsContent: {
        width: "100%",
    },
    tabContent: {
        width: "100%",
        display: "none",
        padding: "15px 0",
    },
    tabContentActive: {
        display: "block",
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.color4}`,
    },
    tab: {
        color: theme.palette.primary.main,
    },
    tabsErrors: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    tabError: {
        flexGrow: 1,
        textAlign: "center",
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        visibility: "hidden",
        height: 0,
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
            animation: "blinkError 3s infinite",
        },
        "&.parentFieldError": {
            height: "auto",
            visibility: "visible",
        }
    },
}));

export default useStyles;