import React from 'react';
import {Snackbar} from "@mui/material";
import {Alert} from '@mui/material';

const SuccessMessage = (props) => {
    const {
        isSuccessMessageActive,
        setSuccessMessageActive,
        setSuccessMessageText,
        successMessageText
    } = props;
    return (
        <Snackbar
            open={isSuccessMessageActive}
            autoHideDuration={successMessageText ? 10000 : 5000}
            onClose={() => {
                setSuccessMessageActive(false);
                setSuccessMessageText(null);
            }}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <Alert variant="filled" severity="success">
                {successMessageText || "Wykonano pomyślnie"}
            </Alert>
        </Snackbar>
    );
}

export default SuccessMessage;