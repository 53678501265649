import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Clear} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {
    createDateGlobalValue,
    createInitialDateLocalValue,
} from "@tools/date";
import useStyles from "../styles";

const DateInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const initialLocalValue = createInitialDateLocalValue(inputValue);

    const [acceptedLocalValue, setAcceptedLocalValue] = useState(initialLocalValue);
    const [acceptedGlobalValue, setAcceptedGlobalValue] = useState(inputValue);

    const [value, setValue] = useState(initialLocalValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, inputValue, props);
    })

    const classes = useStyles();

    return (
        <>
            <MobileDatePicker
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                toolbarTitle={"Wybierz datę"}
                cancelText={"Anuluj"}
                okText={"Zatwierdź"}
                disabled={!!props.field.options.disabled || !!props.field.attributes.readonly}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                onAccept={(newValue) => {
                    setValue(newValue);
                    setGlobalFormFieldValue(inputName, createDateGlobalValue(newValue), props);

                    setAcceptedLocalValue(newValue);
                    setAcceptedGlobalValue(createDateGlobalValue(newValue));
                }}
                onClose={() => {
                    setValue(acceptedLocalValue);
                    setGlobalFormFieldValue(inputName, acceptedGlobalValue, props);
                }}
                value={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size={"small"}
                        fullWidth
                        variant={"outlined"}
                        label={createInputLabel(props.field.label, props.field.required)}
                        name={inputName}
                        id={inputName}
                    />
                )}
            />
            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue(null);
                            setGlobalFormFieldValue(inputName, "", props);

                            setAcceptedLocalValue(null);
                            setAcceptedGlobalValue("");
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default DateInput;
