import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from '@s-ui/react-hooks';
import {Clear} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import useStyles from "../styles";

const BasicInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <TextField
                size="small"
                fullWidth
                readOnly={props.field.attributes.readonly}
                disabled={props.field.options.disabled}
                type={props.field.type}
                id={inputName}
                name={inputName}
                label={createInputLabel(props.field.label, props.field.required)}
                variant="outlined"
                value={value || ""}
                color={"primary"}
                onChange={event => setValue(event.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={event => {
                    if (props.field.type === "number" && [
                        "-",
                        "+",
                        "e",
                    ].includes(event.key)) {
                        event.preventDefault();
                    }
                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                        event.preventDefault();
                    }
                    event.key === 'Enter' && setGlobalFormFieldValue(inputName, event.target.value, props);
                }}
                onBlur={event => setGlobalFormFieldValue(inputName, event.target.value, props)}
            />
            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue(null);
                            setGlobalFormFieldValue(inputName, null, props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default BasicInput;
