import React from 'react';
import {FormLabel} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {createInputLabel} from "@tools/fields";

const StaticText = (props) => {
    const useStyles = makeStyles(theme => ({
        label: {
            color: "#000",
        },
        text: {
            color: "#000",
            margin: "5px 0 0 0",
        },
    }));

    const classes = useStyles();

    return (
        <>
            <FormLabel component="legend" className={classes.label}>
                {createInputLabel(props.field.label, props.field.required)}
            </FormLabel>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: props.field.value}}/>
        </>
    );
}

export default StaticText;