import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getViewInit, getView, setSourceRedirectListUrl} from "@store/actions/apiBuilder/getView";
import ApiBuilder from "@components/views/ApiBuilder/Main";
import {sendApiActionInit} from "@store/actions/apiBuilder/sendApiAction";
import {sendFormDataInit} from "@store/actions/apiBuilder/sendFormData";

const ApiBuilderContainer = (props) => {
    return (
        <ApiBuilder {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getViewInit,
            getView,
            sendFormDataInit,
            sendApiActionInit,
            setSourceRedirectListUrl
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiBuilderContainer);