import React from 'react';
import useStyles from "./styles";

const JsonPreview = (props) => {
    const classes = useStyles();

    const {title, json} = props;

    return (
        <>
            <div className={classes.title}>{title}</div>
            <div className={classes.code}>
                <pre className={classes.pre}>{JSON.stringify(json, null, 4)}</pre>
            </div>
        </>
    );
};

export default JsonPreview;