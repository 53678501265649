import {
    GET_MENU_INIT,
    GET_MENU_SUCCESS,
    GET_MENU_FAILURE
} from "@store/types/user";

import {client} from "@api/client";
import {getAccessToken} from "@tools/tokens";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getMenuInit = () => ({
    type: GET_MENU_INIT
});

const getMenuSuccess = (data) => ({
    type: GET_MENU_SUCCESS,
    payload: data
});

const getMenuFailure = (errors) => ({
    type: GET_MENU_FAILURE,
    payload: errors
});

const getMenu = () => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.GET_MENU));
        await dispatch(getMenuInit());

        const path = '/api/system/menu';
        const url = (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path;

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`
        };

        try {
            const response = await client('GET', url, null, headers);
            const responseData = response.data.data;

            await dispatch(getMenuSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getMenuFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.GET_MENU));
    }
}
export {
    getMenuInit,
    getMenu,
};