import React from 'react';
import StartPage from "@components/views/StartPage/Main";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const StartPageContainer = (props) => {
    return (
        <StartPage {...props}/>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartPageContainer);