import {
    GET_AUTOCOMPLETE_INIT,
    GET_AUTOCOMPLETE_SUCCESS,
    GET_AUTOCOMPLETE_FAILURE
} from "@store/types/apiBuilder";

import {client} from "@api/client";
import {getAccessToken} from "@tools/tokens";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getAutocompleteInit = (name) => ({
    type: GET_AUTOCOMPLETE_INIT,
    payload: name
});

const getAutocompleteSuccess = (data) => ({
    type: GET_AUTOCOMPLETE_SUCCESS,
    payload: data
});

const getAutocompleteFailure = (errors) => ({
    type: GET_AUTOCOMPLETE_FAILURE,
    payload: errors
});

const getAutocomplete = (name, url) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.GET_AUTOCOMPLETE));
        await dispatch(getAutocompleteInit(name));

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client("GET", url, null, headers);

            const data = {
                name: name,
                data: response.data.data,
            }

            await dispatch(getAutocompleteSuccess(data));
        } catch (error) {
            if (error.response) {
                const errors = {
                    name: name,
                    errors: error.response.data.errors,
                }

                await dispatch(getAutocompleteFailure(errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.GET_AUTOCOMPLETE));
    }
}

export {
    getAutocompleteInit,
    getAutocomplete,
};