import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from "./App";
import {Provider} from "react-redux";
import reportWebVitals from './reportWebVitals';
import {ThemeProvider, createTheme} from "@mui/material/styles";
import store from "@store/index";
import {BrowserRouter} from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {pl} from "date-fns/locale";
import {LocalizationProvider} from "@mui/x-date-pickers";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

const theme = createTheme({
    palette: {
        primary: {
            main: "#1DA2E0",
            lighter: "#95d4f1",
            darker: "#1a91c9",
        },
        color1: "#f8f8f8",
        color2: '#3db224',
        color3: '#f0f0f0',
        color4: '#ddd',
        color5: '#555',
        color6: '#777',
        color7: "#ff0000",
    },
    typography: {
        fontFamily: "Roboto",
        fontWeight: 400,
    },
});

ReactDOM.render(
    // <React.StrictMode>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                        <App/>
                    </LocalizationProvider>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>,
    // </React.StrictMode>,
    document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
