import React from 'react';
import {FormHelperText} from "@mui/material";
import FieldErrorContainer from "@components/views/ApiBuilder/views/Form/errors/FieldError";
import BasicInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/BasicInput";
import TextareaInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/TextareaInput";
import CheckboxInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/CheckboxInput";
import DateTimeInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/DateTimeInput";
import DateInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/DateInput";
import TimeInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/TimeInput";
import SwitchInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/SwitchInput";
import RadioInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/RadioInput";
import SelectInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/SelectInput";
import StaticText from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/StaticText";
import HiddenInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/HiddenInput";
import MultiSelectInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/MultiSelectInput";
import MultiCheckboxInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/MultiCheckboxInput";
import EditorInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/EditorInput";
import AutocompleteInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/AutocompleteInput";
import CollectionInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/CollectionInput";
import SubformInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/SubformInput";
import UploadInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/UploadInput";
import {createInputName} from "@tools/fields";
import ColorInput from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/ColorInput";
import useStyles from "./styles";

const Fields = (props) => {
    const classes = useStyles();

    const fieldsConfig = [
        {type: "text", component: BasicInput},
        {type: "number", component: BasicInput},
        {type: "email", component: BasicInput},
        {type: "password", component: BasicInput},
        {type: "color", component: ColorInput},
        {type: "textarea", component: TextareaInput},
        {type: "checkbox", component: CheckboxInput},
        {type: "datetime", component: DateTimeInput},
        {type: "date", component: DateInput},
        {type: "time", component: TimeInput},
        {type: "switch", component: SwitchInput},
        {type: "radio", component: RadioInput},
        {type: "multicheckbox", component: MultiCheckboxInput},
        {type: "select", component: SelectInput},
        {type: "multiselect", component: MultiSelectInput},
        {type: "static_text", component: StaticText},
        {type: "editor", component: EditorInput},
        {type: "autocomplete", component: AutocompleteInput},
        {type: "multiautocomplete", component: AutocompleteInput},
        {type: "collection", component: CollectionInput},
        {type: "subform", component: SubformInput},
        {type: "upload", component: UploadInput},
    ];

    return (
        <>
            {props?.fields?.map((field, index) => (
                <React.Fragment key={index}>
                    {field.type !== "hidden" && (
                        <>
                            {fieldsConfig.filter(fieldConfig => fieldConfig.type === field.type).map((fieldConfig, index) => (
                                <div className={classes.fieldRow} key={index}>
                                    <fieldConfig.component {...props} field={field}/>

                                    {field?.options?.help && (
                                        <FormHelperText id={`${field.name}-helper-text`}>
                                            {field.options.help}
                                        </FormHelperText>
                                    )}

                                    <FieldErrorContainer fieldName={createInputName(field.name, props)} errors={props.form?.formSentErrors}/>
                                </div>
                            ))}
                        </>
                    )}

                    {field.type === "hidden" && <HiddenInput {...props} field={field}/>}
                </React.Fragment>
            ))}
        </>
    );
}

export default Fields;
