import PAGES from "@consts/pages";
import ROUTES from "@consts/routes";
import LoginContainer from "@components/views/Login";
import NotFoundPageContainer from "@components/views/NotFoundPage";
import StartPageContainer from "@components/views/StartPage";
import RecoverPasswordContainer from "@components/views/RecoverPassword";
import ResetPasswordContainer from "@components/views/ResetPassword";
import ApiBuilderContainer from "@components/views/ApiBuilder";

const ROUTES_CONFIG = [
    {
        name: PAGES.HOME,
        path: ROUTES.HOME,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        component: StartPageContainer
    },
    {
        name: PAGES.LOGIN,
        path: ROUTES.LOGIN,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        component: LoginContainer
    },
    {
        name: PAGES.RECOVER_PASSWORD,
        path: ROUTES.RECOVER_PASSWORD,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        component: RecoverPasswordContainer
    },
    {
        name: PAGES.RESET_PASSWORD,
        path: ROUTES.RESET_PASSWORD,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        component: ResetPasswordContainer
    },
    {
        name: PAGES.API_VIEW,
        path: ROUTES.API_VIEW,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        component: ApiBuilderContainer
    },
    {
        name: PAGES.NOT_FOUND,
        path: ROUTES.NOT_FOUND,
        exact: true,
        authRequired: null,
        logoutBeforeMount: false,
        component: NotFoundPageContainer
    }
];

export default ROUTES_CONFIG;