import React from "react";
import {
    Button,
    ClickAwayListener,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import ACTIONS_TYPES from "@consts/actionsTypes";
import {Link} from "react-router-dom";
import {createApiFrontUrl} from "@tools/apiUrl";
import {MoreVert} from "@mui/icons-material";

const Actions = (props) => {
    const {items} = props;

    const randString = Math.random().toString(36).substring(2, 15);

    const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {items?.length > 0 && (
                <>
                    <IconButton
                        size={"small"}
                        ref={anchorRef}
                        aria-controls={open ? randString : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <MoreVert/>
                    </IconButton>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{zIndex: 1}}
                    >
                        {({TransitionProps}) => (
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id={randString}>
                                            {items.map((action, index) => (
                                                <div key={index}>
                                                    {action.type === ACTIONS_TYPES.LINK_BUTTON && (
                                                        <MenuItem
                                                            component={Link}
                                                            to={createApiFrontUrl({requestUrl: action.data.url}, true)}
                                                            target={action.data.openInNewWindow ? "_blank" : ""}
                                                        >
                                                            {action.data.name}
                                                        </MenuItem>
                                                    )}

                                                    {action.type === ACTIONS_TYPES.ACTION_BUTTON && (
                                                        <MenuItem
                                                            onClick={
                                                                (event) => {
                                                                    handleClose(event);
                                                                    event.stopPropagation();
                                                                    props.actions.sendApiActionInit();

                                                                    if (action.data.confirmationRequired) {
                                                                        setOpenedConfirmDialog(`${props.uuid}-${index}`);
                                                                    } else {
                                                                        props.actions.sendApiAction(action.data.method, action.data.url, action.data.data, action.data.successMessage);
                                                                    }
                                                                }
                                                            }
                                                        >
                                                            {action.data.name}
                                                        </MenuItem>
                                                    )}
                                                </div>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                    {items.map((action, index) => (
                        <React.Fragment key={index}>
                            {action.type === ACTIONS_TYPES.ACTION_BUTTON && (
                                <>
                                    {action.data.confirmationRequired && (
                                        <Dialog
                                            open={openedConfirmDialog === `${props.uuid}-${index}`}
                                            onClose={() => setOpenedConfirmDialog(null)}
                                            aria-labelledby="alert"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            {props.apiBuilder.view.apiActionSentErrors ? (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {props.apiBuilder.view.apiActionSentErrors.length === 1 ? "Wystapił błąd" : "Wystąpiły błędy"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            {props.apiBuilder.view.apiActionSentErrors.map((error, index) => {
                                                                return (
                                                                    <ul key={index}>
                                                                        <li>{error.code}<br/>{error.message}</li>
                                                                    </ul>
                                                                )
                                                            })}
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                () => setOpenedConfirmDialog(null)
                                                            }
                                                            color="primary"
                                                        >
                                                            Wróć do listy
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    window.location.reload(true);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Odśwież stronę
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    props.actions.sendApiAction(action.data.method, action.data.url, action.data.data, action.data.successMessage);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Spróbuj ponownie
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            ) : (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {action.data.name}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <b><span dangerouslySetInnerHTML={{__html: action.data.confirmationTitle}}/></b><br/>
                                                            <span dangerouslySetInnerHTML={{__html: action.data.confirmationText}}/>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                () => setOpenedConfirmDialog(null)
                                                            }
                                                            color="primary"
                                                        >
                                                            Anuluj
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    props.actions.sendApiAction(action.data.method, action.data.url, action.data.data, action.data.successMessage);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Tak
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </Dialog>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </>
            )}
        </>
    )
}

export default Actions;