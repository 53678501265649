import {
    LOGOUT,
} from "@store/types/user";

import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import {getAccessToken, removeTokens} from "@tools/tokens";
import {client} from "@api/client";
import LOADER_PLACES from "@consts/loaderPlaces";

const logoutUser = () => ({
    type: LOGOUT
});

const logout = (navigate, withRedirect) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.LOGOUT));

        const path = '/api/auth/logout';
        const url = (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path;

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        const data = {};

        try {
            await client('POST', url, data, headers);
        } catch (error) {
        }

        removeTokens();
        await dispatch(logoutUser());

        await dispatch(resetMultiLoader(LOADER_PLACES.LOGOUT));
    }
}

export default logout;