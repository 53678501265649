import React from "react";
import {makeStyles} from "@mui/styles";

const OutlinedDiv = ({children, label}) => {
    const useStyles = makeStyles(theme => ({
        fieldset: {
            padding: "10px 15px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            position: "relative",
            borderRadius: "4px",
            "&:hover": {
                border: "1px solid rgba(0, 0, 0, 0.87)",
            }
        },
        legend: {
            position: "absolute",
            top: 0,
            left: "7px",
            transform: "translate(0, -50%)",
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.54)",
            padding: "0 5px",
            fontSize: "13px",
        },
        fieldsetContent: {
        },
    }));

    const classes = useStyles();

    return (
        <fieldset className={classes.fieldset}>
            <legend className={classes.legend}>{label}</legend>
            <div className={classes.fieldsetContent}>
                {children}
            </div>
        </fieldset>
    );
};
export default OutlinedDiv;
