import React from 'react';
import Button from "@mui/material/Button";
import {
    Grid, Paper,
} from "@mui/material";
import JsonPreviewContainer from "@components/shared/JsonPreview";
import clsx from "clsx";
import Row from "@components/views/ApiBuilder/views/shared/Row";
import {createApiFrontUrl, getSourceRedirectListUrl, redirectToSourceRedirectListUrl} from "@tools/apiUrl";
import {useNavigate} from "react-router";
import useStyles from "./styles";

const Show = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <Paper elevation={3} className={classes.paper}>
                <div>
                    {props.config.elements.rows.map((row, index) => (
                        <Row key={index} {...props} row={row}/>
                    ))}
                    <div className={classes.footerActions}>
                        <Grid container spacing={2} justifyContent={"flex-end"}>
                            {props.config?.footer?.filter(
                                button => button.type === "linkButton"
                            )?.map((button, index) => (
                                <Grid item xs={2} key={index}>
                                    <Button
                                        className={
                                            clsx(
                                                classes.actionBtn,
                                                classes.actionLinkBtn
                                            )
                                        }
                                        fullWidth
                                        type="button"
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        onClick={
                                            () => {
                                                navigate(
                                                    redirectToSourceRedirectListUrl(button.data.url)
                                                        ? getSourceRedirectListUrl()
                                                        : createApiFrontUrl({requestUrl: button.data.url}, true)
                                                )
                                            }
                                        }
                                    >
                                        {button.data.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </Paper>

            {localStorage.getItem('jsonPreview') && (
                <JsonPreviewContainer
                    title="Api show config"
                    json={props.config}
                />
            )}
        </>
    );
}

export default Show;