export const createInputPath = (props) => {
    let fullPath = null;

    if (props.path && props.parentName) {
        fullPath = `${props.path}[${props.parentName}]`;
    } else if (props.parentName) {
        fullPath = `${props.parentName}`;
    }

    if (props.parentType === "collection" && props.collectionIndex) {
        fullPath = `${fullPath}[${props.collectionIndex}]`;
    }

    return fullPath;
};

export const createInputName = (fieldName, props) => {
    let name = fieldName;

    if (props?.path) {
        name = `${props.path}[${fieldName}]`;
    }

    return name;
};

export const getGlobalFormFieldValue = (inputName, props) => {
    let value = props.values;
    const inputPath = getInputPath(inputName);

    for (let key of inputPath) {
        if (value?.hasOwnProperty(key)) {
            value = value[key]; // state value
        } else {
            value = props.field.value; //config default value
        }
    }

    return value;
};

export const setGlobalFormFieldValue = (inputName, value, props) => {
    const inputPath = getInputPath(inputName);

    const lastElementIndex = inputPath.length - 1;
    const key = inputPath[lastElementIndex];

    if (props?.parentType === "collection") {
        return props.handleChangeCollection(key, value, props.collectionIndex);
    } else if (props?.parentType === "subform") {
        return props.handleChangeSubform(key, value);
    } else {
        return props.handleChangeForm(inputName, value);
    }
}

const getInputPath = (inputName) => {
    let getInputPath = inputName.split(/\[([^\][]*)]/);

    return getInputPath.filter(el => el !== "");
}

export const createInputLabel = (label, isRequired) => {
    if(!label) {
        label = "";
    }
    if (isRequired) {
        return `${label} *`;
    }
    return label;
}
