import React, {useState} from 'react';
import {
    Checkbox,
    IconButton,
    Paper, Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip,
} from "@mui/material";
import {Link} from "react-router-dom";
import {ArrowDropDown, ArrowDropUp, Circle} from "@mui/icons-material";
import {createApiFrontUrl} from "@tools/apiUrl";
import clsx from "clsx";
import useStyles from "./styles";
import SCROLL_IDENTIFIERS from "@consts/scrollIdentifiers";
import queryString from "querystring";
import ActionsContainer from "@components/views/ApiBuilder/views/Grid/List/Actions";

const List = (props) => {
    const classes = useStyles();

    const {columns, items, sorting} = props;

    const selectedOrder = sorting?.order;
    const selectedField = sorting?.field;

    const parsedSearch = queryString.parse(window.location.search);
    const orderParam = parsedSearch.order;

    const getSortLink = (name, order) => {
        const keysToUpdate = {
            order: order ? `${name}|${order}` : null,
            scrollTo: SCROLL_IDENTIFIERS.HEADER_ACTIONS,
        };

        return createApiFrontUrl(keysToUpdate, false);
    }

    const getSortIcon = (order) => {
        switch (order) {
            case 'ASC':
                return <ArrowDropUp/>;
            case 'DESC':
                return <ArrowDropDown/>;
            default:
                return (
                    <div className={classes.sortConnectedArrows}>
                        <Circle className={classes.sortConnectedArrowsInitIcon}/>
                        <ArrowDropUp className={classes.sortConnectedArrowsUpIcon}/>
                        <ArrowDropDown className={classes.sortConnectedArrowsDownIcon}/>
                    </div>
                )
        }
    }

    const getSortContainer = (fieldName) => {
        let order;
        let nextOrder;
        let tooltipTitle;

        if (fieldName === selectedField && orderParam) {
            order = selectedOrder;
        } else {
            order = null;
        }

        if (fieldName === selectedField && orderParam) {
            if (selectedOrder === "ASC") {
                nextOrder = "DESC";
                tooltipTitle = "Sortuj malejąco";
            } else if (selectedOrder === "DESC") {
                nextOrder = null;
                tooltipTitle = "Wyczyść sortowanie";
            } else {
                nextOrder = "ASC";
                tooltipTitle = "Sortuj rosnąco";
            }
        } else {
            nextOrder = "ASC";
            tooltipTitle = "Sortuj rosnąco";
        }

        return (
            <div className={classes.sortContainer}>
                <Link to={getSortLink(fieldName, nextOrder)}>
                    <IconButton className={classes.sortIconButton} size="large">
                        <Tooltip title={tooltipTitle} arrow>
                            {getSortIcon(order)}
                        </Tooltip>
                    </IconButton>
                </Link>
            </div>
        )
    }

    const getCell = (uuid, key, value) => {
        if (key === "actions") {
            return (
                <TableCell className={clsx(classes.tableCell, classes.tableCellActions)}>
                    <ActionsContainer items={value} uuid={uuid}/>
                </TableCell>
            )
        } else if (value && typeof value === "object") {
            return (
                <TableCell className={classes.tableCell}>
                    {value?.type === "image" && (
                        <a
                            href={value.data.src}
                            className={classes.imgPreviewContainer}
                            target={"_blank"}
                            rel={"noreferrer"}
                            title={"Kliknij, aby zobaczyć obraz w pełnym rozmiarze"}
                        >
                            <img src={value.data.src} alt="" className={classes.imgPreview}/>
                            <span className={classes.imgPreviewTitle}>{value.data.title}</span>
                        </a>
                    )}

                    {value?.type === "link" && (
                        <a className={classes.link} href={value.data.url} rel={"noreferrer"} target={"_blank"}>
                            {value.data.title}
                        </a>
                    )}
                </TableCell>
            )
        } else if (key === "id" || key === "uuid") {
            return (
                <TableCell className={clsx(classes.tableCell, classes.tableCellUuid)}>
                    {value}
                </TableCell>
            )
        } else {
            return (
                <TableCell className={classes.tableCell}>
                    {value}
                </TableCell>
            )
        }
    }

    const [massSelectedItems, setMassSelectedItems] = useState([]);

    const addMassSelectedItem = (id) => {
        const newItems = [...massSelectedItems, id];

        setMassSelectedItems(newItems);
    }

    const removeMassSelectedItem = (id) => {
        const newItems = massSelectedItems.filter(item => item !== id);

        setMassSelectedItems(newItems);
    }

    const handleChangeMassSelectedItem = (id) => {
        const isExist = massSelectedItems.find(item => item === id);

        if(!isExist) {
            addMassSelectedItem(id);
        } else {
            removeMassSelectedItem(id);
        }
    }

    // useEffect(() => {
    //     console.log(massSelectedItems);
    // }, [massSelectedItems]);

    return (
        <div id={SCROLL_IDENTIFIERS.LIST}>
            {items?.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="Table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell
                                    className={clsx(
                                        classes.headTableCell,
                                        classes.tableCellCheckbox
                                    )}
                                >
                                    <div className={classes.titleCellContent}></div>
                                </TableCell>
                                {columns?.map((column, index) => (
                                    <TableCell
                                        className={clsx(
                                            classes.headTableCell,
                                            column.field === 'actions' && classes.tableCellActions
                                        )}
                                        key={index}
                                    >
                                        <div className={classes.titleCellContent}>
                                            {column.name}
                                            {column?.sortable && items?.length > 1 && getSortContainer(column.field)}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                                <TableRow className={classes.tableRow} key={index}>
                                    <TableCell className={clsx(classes.tableCell, classes.tableCellCheckbox)}>
                                        <Checkbox onChange={() => handleChangeMassSelectedItem(item.uuid)} />
                                    </TableCell>
                                    {Object.entries(item).map(([key, value]) => (
                                        <React.Fragment key={key}>
                                            {getCell(item.uuid, key, value)}
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <>Brak elementów listy</>
            )}
        </div>
    );
}

export default List;
