import React from 'react';
import {Grid} from "@mui/material";
import Row from "@components/views/ApiBuilder/views/shared/Row";
import FieldsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Fields";
import ComponentsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Components";

const BlockContent = (props) => {
    return (
        <>
            {props?.row?.cols?.map((col, index) => (
                <Grid item xs={col.options.size} key={index}>
                    {col?.rows?.map((row, index) => (
                        <Row key={index} {...props} row={row}/>
                    ))}

                    {col?.fields && (
                        <FieldsContainer {...props} fields={col.fields}/>
                    )}

                    {col?.components && (
                        <ComponentsContainer {...props} components={col.components}/>
                    )}
                </Grid>
            ))}
        </>
    );
}

export default BlockContent;