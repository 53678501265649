import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    loader: {
        color: theme.palette.color2,
    },
    waitInfoContainer: {
        position: "absolute",
    },
    waitInfoIcon: {
        width: "24px",
        height: "24px",
        color: theme.palette.color5,
    },
    fullScreenLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100000,
        backgroundColor: "#fff",
        animation: "loaderFadeOut .2s forwards",
    },
    fullScreenLoaderActive: {
        animation: "none",
    },

    fullScreenTransparentLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100000,
        animation: "loaderFadeOut .2s forwards",
        backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    fullScreenTransparentLoaderActive: {
        animation: "none",
    },

    fullScreenForSkeletonLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100000,
        animation: "loaderFadeOut .2s forwards",
    },
    fullScreenForSkeletonLoaderActive: {
        animation: "none",
    },
}));

export default useStyles;