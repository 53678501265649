import {makeStyles} from "@mui/styles";

const useStylesAdBlockDetect = makeStyles(theme => ({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.color1,
        zIndex: 1200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    title: {
        color: theme.palette.color7,
        textAlign: "center",
    },
    informationList: {
        color: theme.palette.color7,
    },
    informationListItem: {
        margin: "15px 0",
    },
    information: {
        color: theme.palette.color7,
        textAlign: "center",
    },
}));

export default useStylesAdBlockDetect;