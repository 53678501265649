import React, {useState, useRef} from 'react';
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Editor} from '@tinymce/tinymce-react';
import {FormLabel} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const EditorInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const closeInfo = () => {
        const timer = setTimeout(() => {
            const closeBtn = document.getElementsByClassName('tox-notification__dismiss');
            if (closeBtn.length > 0) {
                closeBtn[0].click();
            }
        }, 1000);
        return () => clearTimeout(timer);
    }

    const editorRef = useRef(null);

    const useStyles = makeStyles(theme => ({
        editorContainer: {
            position: "relative",
        },
        label: {
            position: "absolute",
            top: 0,
            left: "7px",
            transform: "translate(0, -50%)",
            backgroundColor: "#fff",
            color: "rgba(0, 0, 0, 0.54)",
            padding: "0 5px",
            fontSize: "13px",
            zIndex: 2,
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.editorContainer}>
            <FormLabel component="legend" className={classes.label}>
                {createInputLabel(props.field.label, props.field.required)}
            </FormLabel>
            <Editor
                apiKey={"k6292ylavvl9o5tzxh94kbbh7m1hoyw2q3kdo5aej4qt5ovt"}
                onLoadContent={() => closeInfo()}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                }}
                onBlur={(evt, editor) => {
                    setValue(editorRef.current.getContent())
                    setGlobalFormFieldValue(inputName, editorRef.current.getContent(), props)
                }}
                initialValue={value}
                init={{
                    height: 500,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    contextmenu: "link linkchecker image imagetools lists table spellchecker"
                }}
            />
        </div>
    );
}

export default EditorInput;
