import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
        margin: "60px auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    avatar: {
        width: "64px",
        height: "64px",
        margin: "0 0 15px 0",
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        width: "36px",
        height: "36px",
    },
    paragraph: {
        margin: "15px 0 0 0",
        textAlign: "center",
    },
}));

export default useStyles;