import React from 'react';
import CollapseContent
    from "@components/views/ApiBuilder/views/shared/Row/contentTypes/CollapseContent/Main";

const CollapseContentContainer = (props) => {
    return (
        <CollapseContent {...props}/>
    );
}

export default CollapseContentContainer;