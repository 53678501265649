import {CssBaseline} from "@mui/material";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LoaderContainer from "@components/layout/Loader";
import {autoLogin} from "@store/actions/user";
import {useEffect} from "react";
import {getAccessToken} from "@tools/tokens";
import {setAppReady} from "@store/actions/config";
import LayoutContainer from "@components/layout/Layout";
import InternetConnectionContainer from "@components/layout/InternetConnection";
import {useDetectAdBlock} from "adblock-detect-react";
import AdBlockDetectContainer from "@components/layout/AdBlockDetect";

const App = ({actions, config}) => {
    const adBlockDetected = useDetectAdBlock();

    useEffect(() => {
        if (getAccessToken()) {
            actions.autoLogin();
        } else {
            actions.setAppReady();
        }
    }, [actions]);

    return (
        <>
            <CssBaseline/>
            <LoaderContainer/>
            <InternetConnectionContainer/>

            {adBlockDetected ? (
                <AdBlockDetectContainer/>
            ) : (
                <>
                    {config.appReady && <LayoutContainer/>}
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    config: state.config,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            autoLogin,
            setAppReady,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
