import React, {useEffect} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import Row from "@components/views/ApiBuilder/views/shared/Row";
import FieldsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Fields";
import {ErrorOutline, ExpandMore} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {v4 as uuidv4} from "uuid";
import clsx from "clsx";
import {setParentFieldError} from "@tools/hiddenFormContentErrors";
import ComponentsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Components";

const CollapseContent = (props) => {
    const useStyles = makeStyles(theme => ({
        accordion: {
            "&.Mui-expanded": {
                margin: 0,
            },
        },
        accordionError: {
            width: "100%",
            textAlign: "center",
            fontSize: "13px",
            fontWeight: 600,
            color: theme.palette.color7,
            alignItems: "center",
            display: "flex",
            visibility: "hidden",
            height: 0,
            animation: "blinkError 3s infinite",
            "& svg": {
                width: "20px",
                margin: "0 5px 0 0",
            },
            "&.parentFieldError": {
                height: "auto",
                visibility: "visible",
            }
        },
    }));

    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(props?.row?.options?.expanded);

    const handleChangeCollapseExpanded = () => {
        setExpanded(!expanded);
    };

    const uuid = uuidv4();

    useEffect(() => {
        setParentFieldError("collapseContent");
    }, [props.form?.formSentErrors, uuid]);

    return (
        <Grid item xs={12}>
            <Accordion
                className={clsx(classes.accordion, 'collapseContent')}
                expanded={expanded}
                onChange={handleChangeCollapseExpanded}
                id={uuid}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="collapse-content"
                    id="collapse-header"
                >
                    <Typography>
                        {expanded ? "Ukryj zawartość" : "Pokaż zawartość"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {props?.row?.cols?.map((col, index) => (
                            <Grid item xs={col.options.size} key={index}>
                                {col?.rows?.map((row, index) => (
                                    <Row key={index} {...props} row={row}/>
                                ))}

                                {col?.fields && (
                                    <FieldsContainer {...props} fields={col.fields}/>
                                )}

                                {col?.components && (
                                    <ComponentsContainer {...props} components={col.components}/>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <div
                className={classes.accordionError}
                id={`collapse-error-${uuid}`}
            >
                <ErrorOutline/> Sekcja rozwijana zawiera błędy
            </div>
        </Grid>
    );
}

export default CollapseContent;