import React, {useState} from 'react';
import {FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Clear} from "@mui/icons-material";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";
import useStyles from "../styles";

const RadioInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <OutlinedDiv label={createInputLabel(props.field.label, props.field.required)}>
                <RadioGroup
                    aria-label={props.field.name}
                    name={inputName}
                    id={inputName}
                    value={value}
                    onChange={event => {
                        setValue(event.target.value);
                        setGlobalFormFieldValue(inputName, event.target.value, props);
                    }}
                >
                    {props.field.choices.map(choice => (
                        <FormControlLabel
                            readOnly={props.field.attributes.readonly}
                            disabled={props.field.options.disabled}
                            value={choice.value}
                            control={<Radio color="primary" size={"small"}/>}
                            label={choice.name}
                            key={choice.value}
                        />
                    ))}
                </RadioGroup>
            </OutlinedDiv>

            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue("");
                            setGlobalFormFieldValue(inputName, "", props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default RadioInput;