const parentFieldErrorClassName = 'parentFieldError';

export const setParentFieldError = (type) => {
    setTimeout(() => {
        //search all fields error messages
        const fieldErrors = document.getElementsByClassName('fieldError');

        //loop on each field
        Array.from(fieldErrors).forEach((el, index) => {
            let activeEl = el;

            //until parent is found
            while (activeEl) {
                activeEl = activeEl.parentNode;

                if (activeEl) {
                    //tabContent
                    if (type === "tabContent") {
                        if (activeEl.nodeType === 1 && activeEl.hasAttribute('class') && activeEl.classList.contains('tabContent')) {
                            const tabId = activeEl.getAttribute('id'); //info: tabId contains also index of tabs
                            const tabWithError = document.getElementById(`tab-error-${tabId}`);
                            if (!tabWithError.classList.contains(parentFieldErrorClassName)) {
                                tabWithError.classList.add(parentFieldErrorClassName);
                            }
                        }
                    }

                    //collapseContent
                    if (type === "collapseContent") {
                        if (activeEl.nodeType === 1 && activeEl.hasAttribute('class') && activeEl.classList.contains('collapseContent')) {
                            const collapseId = activeEl.getAttribute('id');
                            const collapseError = document.getElementById(`collapse-error-${collapseId}`);
                            if (!collapseError.classList.contains(parentFieldErrorClassName)) {
                                collapseError.classList.add(parentFieldErrorClassName);
                            }
                        }
                    }
                }
            }
        });
    }, 100);
}

export const resetParentFieldsErrors = () => {
    //search all error messages
    const parentFieldError = document.getElementsByClassName(parentFieldErrorClassName);
    //delete all error classes
    Array.from(parentFieldError).forEach((el, index) => {
        el.classList.remove(parentFieldErrorClassName);
    });
}