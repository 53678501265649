import React, {useState} from 'react';
import {FormControlLabel, Switch, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

const SwitchInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <OutlinedDiv label={createInputLabel(props.field.label, props.field.required)}>
                <FormControlLabel
                    control={
                        <Switch
                            size={"small"}
                            readOnly={props.field.attributes.readonly}
                            disabled={props.field.options.disabled}
                            checked={value}
                            name={inputName}
                            id={inputName}
                            onChange={event => {
                                setValue(event.target.checked);
                                setGlobalFormFieldValue(inputName, event.target.checked, props);
                            }}
                            color="primary"
                        />
                    }
                    label={value ? "Tak" : "Nie"}
                />
            </OutlinedDiv>

            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue(null);
                            setGlobalFormFieldValue(inputName, null, props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default SwitchInput;