import React from 'react';
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Avatar, Typography} from "@mui/material";
import {Lock} from "@mui/icons-material";
import clsx from "clsx";
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import useStyles from "./styles";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useParams} from "react-router";
import {useMount} from "@s-ui/react-hooks";

const ResetPassword = (props) => {
    const {user} = props;
    const {resetPasswordToken} = useParams();

    const classes = useStyles();

    const fields = BASIC_FORM_FIELDS_CONFIG.RESET_PASSWORD;

    const handleFormValid = (data) => {
        data = {
            ...data,
            token: resetPasswordToken,
        }

        props.actions.resetPassword(data);
    }

    useMount(() => {
        props.actions.getResetPasswordToken(resetPasswordToken);
    });

    return (
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <Lock className={classes.icon}/>
            </Avatar>

            <Typography component={"h1"} variant={"h5"}>
                Resetowanie hasła
            </Typography>

            {user.profile.getRecoverPasswordTokenErrors ? (
                <div className={clsx(classes.message, classes.messageError)}>
                    Token jest niepoprawny lub wygasł.
                </div>
            ) : (
                <>
                    {user.profile.resetPasswordSuccess ? (
                        <div className={clsx(classes.message, classes.messageSuccess)}>
                            Hasło zostało zmienione. Możesz <Link to={ROUTES.LOGIN} className={classes.link}>zalogować
                            się</Link> do konta.
                        </div>
                    ) : (
                        <BasicFormBuilderContainer
                            fields={fields}
                            errors={user.profile.resetPasswordErrors}
                            handleFormValid={handleFormValid}
                            btnText="Utwórz nowe hasło"
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default ResetPassword;