import React from 'react';
import {bindActionCreators} from "redux";
import {resetPathAfterLogin, setPathAfterLogin} from "@store/actions/config";
import {connect} from "react-redux";
import {logout} from "@store/actions/user";
import {getViewInit} from "@store/actions/apiBuilder/getView";
import Authorization from "@components/shared/Authorization/Main";

const AuthorizationContainer = (props) => {
    return (
        <Authorization {...props}/>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getViewInit,
            setPathAfterLogin,
            resetPathAfterLogin,
            logout,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorizationContainer);