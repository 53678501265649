const SET_APP_READY = 'SET_APP_READY';
const SET_PATH_AFTER_LOGIN = 'SET_PATH_AFTER_LOGIN';
const RESET_PATH_AFTER_LOGIN = 'RESET_PATH_AFTER_LOGIN';
const SET_MULTI_LOADER = 'SET_MULTI_LOADER';
const RESET_MULTI_LOADER = 'RESET_MULTI_LOADER';

export {
    SET_APP_READY,
    SET_PATH_AFTER_LOGIN,
    RESET_PATH_AFTER_LOGIN,
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER,
}