import React, {useEffect, useState} from 'react';
import VIEW_TYPES from "@consts/viewTypes";
import GridContainer from "@components/views/ApiBuilder/views/Grid";
import FormContainer from "@components/views/ApiBuilder/views/Form";
import SkeletonContentContainer from "@components/views/ApiBuilder/SkeletonContent";
import ErrorsContainer from "@components/views/ApiBuilder/Errors";
import SuccessMessageContainer from "@components/views/ApiBuilder/SuccessMessage";
import {useLocation} from "react-router";
import {useMount, usePrevious} from "@s-ui/react-hooks";
import {createApiBackendUrl, createApiFrontUrl} from "@tools/apiUrl";
import ShowContainer from "@components/views/ApiBuilder/views/Show";

const ApiBuilder = (props) => {
    const [isSuccessMessageActive, setIsSuccessMessageActive] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState(null);
    const [isRequestUrlCorrect, setIsRequestUrlCorrect] = useState(false);

    const location = useLocation();
    const prevLocation = usePrevious(location);

    const {actions, apiBuilder} = props;

    const sendRequest = () => {
        const fullRequestUrl = createApiBackendUrl();
        if (fullRequestUrl) {
            setIsRequestUrlCorrect(true);

            actions.getView(fullRequestUrl);
        } else {
            setIsRequestUrlCorrect(false);
        }
    }

    useMount(() => {
        sendRequest();
    });

    useEffect(() => {
        if (apiBuilder.view.viewData?.viewType === VIEW_TYPES.GRID) {
            const sourceRedirectListUrl = createApiFrontUrl({}, false);

            actions.setSourceRedirectListUrl(sourceRedirectListUrl);
        }
    }, [actions, apiBuilder.view.viewData?.viewType]);

    useEffect(() => {
        if (apiBuilder.form.formSent) {
            setIsSuccessMessageActive(true);
        }
    }, [apiBuilder.form.formSent]);

    useEffect(() => {
        if (apiBuilder.view.apiActionSent) {
            setSuccessMessageText(apiBuilder.view.apiActionSentSuccessMessage);
            setIsSuccessMessageActive(true);

            actions.sendApiActionInit();
        }

    }, [actions, apiBuilder.view.apiActionSent, apiBuilder.view.apiActionSentSuccessMessage]);

    useEffect(() => {
        if (apiBuilder.view.apiActionSent) {
            sendRequest();
        }
    });

    useEffect(() => {
        if (prevLocation && (location.search !== prevLocation?.search)) {
            sendRequest();
        }
    });

    const getView = () => {
        if (apiBuilder.view.viewData?.viewType === VIEW_TYPES.GRID) {
            return <GridContainer/>
        } else if (apiBuilder.view.viewData?.viewType === VIEW_TYPES.FORM) {
            return <FormContainer/>
        } else if (apiBuilder.view.viewData?.viewType === VIEW_TYPES.SHOW) {
            return <ShowContainer/>
        } else if (!apiBuilder.view.viewData && !apiBuilder.view.viewErrors) {
            return <SkeletonContentContainer/>
        } else if (apiBuilder.view.viewErrors) {
            return <ErrorsContainer/>
        }
    }

    return (
        <>
            {isRequestUrlCorrect ? (
                <>{getView()}</>
            ) : (
                <>Wprowadzony parametr 'requestUrl' jest nieprawidłowy.</>
            )}

            <SuccessMessageContainer
                isSuccessMessageActive={isSuccessMessageActive}
                setSuccessMessageActive={setIsSuccessMessageActive}
                setSuccessMessageText={setSuccessMessageText}
                successMessageText={successMessageText}
            />
        </>
    );
}

export default ApiBuilder;