import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: "15px 0",
        padding: "15px",
    },
    footerActions: {
        margin: "30px 0 0 0",
        padding: "15px",
        borderTop: `1px solid ${theme.palette.color4}`,
    },
    actionBtn: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.darker,
        }
    },
    actionLinkBtn: {
        backgroundColor: theme.palette.color5,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.color6,
        }
    },
}));

export default useStyles;