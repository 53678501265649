import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const TableComponent = (props) => {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="Table">
                <TableHead>
                    <TableRow>
                        {props.component.data.columns?.map((column, index) => (
                            <TableCell key={index}>
                                <div>
                                    {column}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.component.data.items?.map((item, index) => (
                        <TableRow key={index}>
                            {Object.entries(item).map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <TableCell>
                                        {value}
                                    </TableCell>
                                </React.Fragment>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableComponent;
