import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from '@s-ui/react-hooks';
import {Clear} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import clsx from "clsx";
import useStyles from "../styles";

const ColorInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <div style={{position: "relative"}}>
                <TextField
                    size="small"
                    fullWidth
                    readOnly={props.field.attributes.readonly}
                    disabled={props.field.options.disabled}
                    type={"text"}
                    id={inputName}
                    name={inputName}
                    label={createInputLabel(props.field.label, props.field.required)}
                    variant="outlined"
                    value={value || ""}
                    color={"primary"}
                    onChange={event => setValue(event.target.value)}
                    onKeyDown={event => event.key === 'Enter' && setGlobalFormFieldValue(inputName, event.target.value, props)}
                    onBlur={event => setGlobalFormFieldValue(inputName, event.target.value, props)}
                />
                <div className={clsx(classes.colorPreview, value && classes.colorPreviewBorder)}
                     style={{backgroundColor: `${value || ""}`}}/>
            {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue(null);
                            setGlobalFormFieldValue(inputName, null, props);
                        }
                    }/>
                </Tooltip>
            )}
            </div>
        </>
    );
}

export default ColorInput;