import {
    SEND_FORM_DATA_INIT,
    SEND_FORM_DATA_SUCCESS,
    SEND_FORM_DATA_FAILURE,

    GET_AUTOCOMPLETE_INIT,
    GET_AUTOCOMPLETE_SUCCESS,
    GET_AUTOCOMPLETE_FAILURE,
} from "@store/types/apiBuilder";

const initialState = {
    formSent: false,
    redirectUrl: null,
    formSentErrors: null,

    autocompleteOptions: {},
    autocompleteOptionsErrors: {},
}

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_FORM_DATA_INIT: {
            return {
                ...state,
                formSent: initialState.formSent,
                redirectUrl: initialState.redirectUrl,
                formSentErrors: initialState.formSentErrors,
            };
        }
        case SEND_FORM_DATA_SUCCESS: {
            return {
                ...state,
                formSent: true,
                redirectUrl: action.payload,
                formSentErrors: initialState.formSentErrors,
            };
        }
        case SEND_FORM_DATA_FAILURE: {
            return {
                ...state,
                formSent: false,
                redirectUrl: initialState.redirectUrl,
                formSentErrors: action.payload,
            };
        }

        case GET_AUTOCOMPLETE_INIT: {
            return {
                ...state,
                autocompleteOptions: {
                    ...state.autocompleteOptions,
                    [action.payload]: null,
                },
                autocompleteOptionsErrors: {
                    ...state.autocompleteOptionsErrors,
                    [action.payload]: null,
                },
            };
        }
        case GET_AUTOCOMPLETE_SUCCESS: {
            return {
                ...state,
                autocompleteOptions: {
                    ...state.autocompleteOptions,
                    [action.payload.name]: action.payload.data
                },
                autocompleteOptionsErrors: {
                    ...state.autocompleteOptionsErrors,
                    [action.payload.name]: null
                },
            };
        }
        case GET_AUTOCOMPLETE_FAILURE: {
            return {
                ...state,
                autocompleteOptions: {
                    ...state.autocompleteOptions,
                    [action.payload.name]: null
                },
                autocompleteOptionsErrors: {
                    ...state.autocompleteOptionsErrors,
                    [action.payload.name]: action.payload.errors
                },
            };
        }
        default:
            return state;
    }
}

export default formReducer;