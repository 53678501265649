import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    main: {
        position: "relative",
        flex: 1,
        zIndex: 1,
        marginTop: "65px",
    },
}));

export default useStyles;