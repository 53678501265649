import React from 'react';
import RecoverPassword from "@components/views/RecoverPassword/Main";
import {connect} from "react-redux";
import {recoverPasswordInit, recoverPassword} from "@store/actions/user";
import {bindActionCreators} from "redux";

const RecoverPasswordContainer = (props) => {
    return (
        <RecoverPassword {...props}/>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            recoverPasswordInit,
            recoverPassword,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecoverPasswordContainer);