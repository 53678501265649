import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    fieldRow: {
        marginBottom: "15px",
        position: "relative",
        "&:last-of-type": {
            marginBottom: 0,
        }
    },
    clearIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: "2px",
        width: "20px",
        height: "20px",
        cursor: "pointer",
        borderRadius: "50%",
        transform: "translate(50%, -50%)",
        border: `1px solid ${theme.palette.color4}`,
        backgroundColor: "#fff",
        color: theme.palette.color5,
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.palette.color4,
        },
    },
    editor: {
        "& .DraftEditor-root": {
            border: `1px solid ${theme.palette.color3}`,
            padding: "0 10px",
            minHeight: "100px",
            maxHeight: "500px",
            overflowY: "auto",
        },
    },
    colorPreview: {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    colorPreviewBorder: {
        border: `1px solid ${theme.palette.color4}`,
    },
    inlineMultiCheckboxFormGroup: {
        flexDirection: "row",
    },
    inlineMultiCheckboxLabel: {
        margin: 0,
        width: "33%",
    },
}));

export default useStyles;