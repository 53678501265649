import {
    LOGIN_USER_INIT,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader, resetPathAfterLogin} from "@store/actions/config";
import {setTokens} from "@tools/tokens";
import {getMenu} from "@store/actions/user/profile/menu";
import LOADER_PLACES from "@consts/loaderPlaces";
import store from "@store/index";

const loginInit = () => ({
    type: LOGIN_USER_INIT,
});

const loginSuccess = (data) => ({
    type: LOGIN_USER_SUCCESS,
    payload: data
});

const loginFailure = (errors) => ({
    type: LOGIN_USER_FAILURE,
    payload: errors
});

const login = (data, navigate) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.LOGIN));
        await dispatch(loginInit());

        const path = '/api/auth/login';
        const url = (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path;

        const headers = {};

        data = {
            ...data,
            clientId: window?._env_?.REACT_APP_API_CLIENT_ID || process.env.REACT_APP_API_CLIENT_ID,
        };

        try {
            const response = await client('POST', url, data, headers);
            const responseData = response.data.data;
            const userData = responseData.user;

            setTokens(responseData.accessToken, responseData.refreshToken);
            await dispatch(loginSuccess(userData));
            navigate(store.getState().config.pathAfterLogin, {replace: true});
            await dispatch(resetPathAfterLogin());

            await dispatch(getMenu());
        } catch (error) {
            if (error.response) {
                await dispatch(loginFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.LOGIN));
    }
}

export {
    loginInit,
    login,
};