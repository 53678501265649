import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    serverErrors: {
        margin: "0 0 15px 0",
    },
    title: {
        fontWeight: 700,
        margin: "0 0 15px 0",
        padding: "10px",
        color: "#fff",
        backgroundColor: theme.palette.color7,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
    error: {
        color: theme.palette.color7,
        fontSize: "13px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
}));

export default useStyles;