import {makeStyles} from "@mui/styles";

const useStylesInternetConnection = makeStyles(theme => ({
    internetConnection: {
        position: "fixed",
        bottom: "10px",
        right: "10px",
        zIndex: 99999,
    },
    message: {
        fontSize: "15px",
        display: "flex",
        alignItems: "center",
        padding: "15px 20px",
        textAlign: "center",
        borderRadius: "10px",
        color: "#fff",
        backgroundColor: theme.palette.color5,
    },
    messageIconOffline: {
        color: theme.palette.color3,
    },
    messageIconOnline: {
        color: theme.palette.color2,
    },
    text: {
        color: "#fff",
        margin: "0 10px",
    },
    refreshText: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    closeButton: {
        color: theme.palette.color3,
        margin: "0 0 0 10px",
        outline: "none",
        cursor: "pointer",
        "&:hover": {
        }
    }
}));

export default useStylesInternetConnection;