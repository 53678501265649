import React, {useEffect, useState} from 'react';
import {
    AppBar,
    ClickAwayListener,
    Drawer,
    Grow,
    IconButton,
    List,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Toolbar,
} from "@mui/material";
import {
    AccountCircle, AddCircle, Home,
    RadioButtonChecked,
    RadioButtonUnchecked, RemoveCircleOutline,
    VpnKey
} from "@mui/icons-material";
import {Link, NavLink} from "react-router-dom";
import ROUTES from "@consts/routes";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";
import {createApiFrontUrl} from "@tools/apiUrl";
import {getMetaTitle, setMetaTitle} from "@tools/metaTags";
import useStyles from "./styles";
import * as queryString from "query-string";
import {useMount, usePrevious} from "@s-ui/react-hooks";
import {useLocation} from "react-router";

const Header = (props) => {
    const classes = useStyles();
    const [showMenu, setShowMenu] = React.useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [expandedGroupsUuid, setExpandedGroupsUuid] = useState([]);

    const location = useLocation();
    const prevLocation = usePrevious(location);

    const {routeConfig, user, apiBuilder, actions} = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const getFirstNameFirstLetter = (firstName) => {
        return firstName.charAt(0);
    }

    const EnvInfo = () => {
        const env = window?._env_?.REACT_APP_ENV || process.env.REACT_APP_ENV;

        if (env === "prod") {
            return <div className={clsx(classes.envInfo, classes.envInfoRed)}>Wprowadzasz zmiany na CRM Produkcja</div>
        }

        if (env === "stage") {
            return <div className={clsx(classes.envInfo, classes.envInfoGreen)}>CRM Test</div>
        }

        return <div className={classes.envInfo}>Środowisko nierozpoznane</div>
    }

    const MenuItemComponent = ({menuItem, type}) => {
        return (
            <>
                {menuItem && (
                    <div className={classes.menuItem}>
                        <div
                            className={type === "child" ? classes.groupTitleChild : classes.groupTitle}
                            onClick={(event) => {
                                event.stopPropagation();
                                addActiveGroup(menuItem.uuid);
                            }}
                        >
                            {type === "child" && (
                                <>
                                    {isActiveGroup(menuItem.uuid) ? (
                                        <RemoveCircleOutline className={classes.swapIcon}/>
                                    ) : (
                                        <AddCircle className={classes.swapIcon}/>
                                    )}
                                </>
                            )}
                            {menuItem.name}
                        </div>
                        <List
                            className={
                                clsx(
                                    type === "child" ? classes.groupListChild : classes.groupList,
                                    isActiveGroup(menuItem.uuid) && classes.groupListActive
                                )
                            }
                            id={menuItem.uuid}
                        >
                            {menuItem?.children?.map((child, index) => (
                                <div className={classes.listItem} key={index}>
                                    {child.type === "GROUP" ? (
                                        <MenuItemComponent menuItem={child} type="child"/>
                                    ) : (
                                        <NavLink
                                            data-requesturl={child.url}
                                            to={createApiFrontUrl({requestUrl: child.url}, true)}
                                            className={
                                                clsx(
                                                    type === "child" ? classes.listItemLinkChild : classes.listItemLink,
                                                    checkNavItemIsActive(child.url) && classes.listItemLinkActive,
                                                    'navLink'
                                                )
                                            }
                                            id={child.uuid}
                                        >
                                            {checkNavItemIsActive(child.url) ?
                                                <RadioButtonChecked className={classes.listItemIcon}/> :
                                                <RadioButtonUnchecked className={classes.listItemIcon}/>
                                            }
                                            {child.name}
                                        </NavLink>
                                    )}
                                </div>
                            ))}
                        </List>
                    </div>
                )}
            </>
        )
    }

    const checkNavItemIsActive = (itemUrl) => {
        const parsedSearch = queryString.parse(location.search);
        const requestUrl = parsedSearch.requestUrl;

        return itemUrl === requestUrl;
    }

    const addActiveGroup = (uuid) => {
        const isActive = isActiveGroup(uuid);

        if (isActive) {
            removeActiveGroup(uuid);
        } else {
            setExpandedGroupsUuid([]);
            setExpandedGroupsUuidValue(uuid);
        }
    }

    const isActiveGroup = (uuid) => {
        return expandedGroupsUuid.includes(uuid);
    }

    const removeActiveGroup = (uuid) => {
        const filteredGroup = expandedGroupsUuid.filter(item => item !== uuid);

        setExpandedGroupsUuidValue(filteredGroup);
    }

    const getPath = (model, uuid) => {
        let path, item = model.uuid;

        if (!model || typeof model !== 'object') {
            return;
        }

        if (model.uuid === uuid) {
            return [item];
        }

        (model.children || []).some(child => (path = getPath(child, uuid)));

        return path && [item, ...path];
    }

    const setExpandedGroupsUuidValue = (activeItemUuid) => {
        const items = user.profile?.menuItems?.systemMenu;

        if (!activeItemUuid) {
            setExpandedGroupsUuid([]);
        } else {
            items && items.forEach(
                mt => {
                    const result = getPath(mt, activeItemUuid);
                    if (result) {
                        setExpandedGroupsUuid([expandedGroupsUuid, ...result]);
                    }
                }
            );
        }
    }

    const getActiveItem = (array, url) => {
        let result;
        array?.some(
            (child) =>
                (child.url === url && (result = child)) ||
                (result = getActiveItem(child.children || [], url))
        );
        return result;
    };

    const initExpandedMenu = () => {
        const parsedSearch = queryString.parse(location.search);
        const requestUrl = parsedSearch.requestUrl;
        const activeItem = getActiveItem(user.profile?.menuItems?.systemMenu, requestUrl);
        const activeItemUuid = activeItem?.uuid;

        setExpandedGroupsUuidValue(activeItemUuid);
    }

    useMount(() => {
        initExpandedMenu();
    });

    useEffect(() => {
        const title = getMetaTitle(routeConfig.name) || apiBuilder.view.viewData?.viewTitle || "Zapytanie do API";

        setMetaTitle(title);
        setPageTitle(title);
    }, [apiBuilder.view.viewData?.viewTitle, routeConfig.name]);

    useEffect(() => {
        if (location.pathname !== prevLocation?.pathname || location.search !== prevLocation?.search) {
            initExpandedMenu();
        }
    });

    return <>
        <AppBar
            position="fixed"
            className={classes.appBar}
        >
            <Toolbar className={classes.toolbar}>
                <div className={classes.toolbarGroup}>
                    {user.auth.isLoggedIn && (
                        <IconButton
                            className={classes.menuButton}
                            aria-label="menu"
                            onClick={() => setShowMenu(true)}
                            size="large">
                            <MenuIcon/>
                        </IconButton>
                    )}
                    <Link to={user.auth.isLoggedIn ? ROUTES.HOME : ROUTES.LOGIN} className={classes.homeLink}>
                        <IconButton size="large">
                            <Home/>
                        </IconButton>
                    </Link>
                    <div className={classes.pageTitleContainer}>
                        <div className={classes.pageTitleDescription}>Aktualna ścieżka:</div>
                        <div className={classes.pageTitle}>{pageTitle}</div>
                    </div>
                </div>
                <div className={classes.toolbarGroup}>
                    <EnvInfo/>

                    <div className={classes.userSection}>
                        <IconButton
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                            size="large">
                            {user.auth.isLoggedIn ? (
                                <div className={classes.userCircle}>
                                    {getFirstNameFirstLetter(user.auth.userData.firstName)}
                                </div>
                            ) : (
                                <VpnKey/>
                            )}
                        </IconButton>

                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                                disablePortal>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow"
                                                      className={classes.menuList}>
                                                {user.auth.isLoggedIn ? (
                                                    <div>
                                                        <div className={classes.userInformation}>
                                                            <AccountCircle className={classes.userIcon}/>
                                                            <div className={classes.userData}>
                                                                <div className={classes.userDataName}>
                                                                    {user.auth.userData.firstName} {user.auth.userData.lastName}
                                                                </div>
                                                                <div className={classes.userDataEmail}>
                                                                    {user.auth.userData.email}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {user.profile.menuItems?.userMenu && (
                                                            <div className={classes.userMenuItemsGroup}>
                                                                {user.profile.menuItems.userMenu.map((menuItem, index) => (
                                                                    <MenuItem
                                                                        className={classes.userMenuItem}
                                                                        key={index}
                                                                        component={Link}
                                                                        to={createApiFrontUrl({requestUrl: menuItem.url}, true)}
                                                                        onClick={handleClose}
                                                                    >
                                                                        {menuItem.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className={classes.userMenuItemsGroup}>
                                                            <MenuItem
                                                                className={classes.userMenuItem}
                                                                onClick={(event) => {
                                                                    handleClose(event);
                                                                    actions.logout();
                                                                }}
                                                            >
                                                                Wyloguj się
                                                            </MenuItem>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <MenuItem
                                                            className={classes.userMenuItem}
                                                            component={Link} to={ROUTES.LOGIN}
                                                            onClick={handleClose}
                                                        >
                                                            Zaloguj się
                                                        </MenuItem>
                                                        <MenuItem
                                                            className={classes.userMenuItem}
                                                            component={Link} to={ROUTES.RECOVER_PASSWORD}
                                                            onClick={handleClose}
                                                        >
                                                            Odzyskaj hasło
                                                        </MenuItem>
                                                    </div>
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </div>
            </Toolbar>
        </AppBar>

        {user.auth.isLoggedIn && (
            <Drawer open={showMenu} onClose={() => setShowMenu(false)} className={classes.drawer}>
                <div role="presentation" onClick={() => setShowMenu(false)} className={classes.menu}>
                    {user.profile.menuItemsErrors?.length ? (
                        <div className={classes.menuMessage}>
                            Wystąpił błąd. Odswież stronę i spróbuj ponownie.
                        </div>
                    ) : (
                        <>
                            {user.profile.menuItems?.systemMenu?.map((menuItem, index) => (
                                <MenuItemComponent key={index} menuItem={menuItem}/>
                            ))}
                        </>
                    )}
                </div>
            </Drawer>
        )}
    </>;
}

export default Header;