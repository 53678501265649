import React from 'react';

const NumberedListComponent = (props) => {
    return (
        <ol>
            {props.component.data.items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ol>
    );
}

export default NumberedListComponent;
