import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    headerActions: {
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 0",
    },
    listInformation: {
        fontWeight: 700,
        fontSize: "15px",
    },
    leftElements: {
        display: "flex",
        alignItems: "flex-end",
    },
    rightElements: {
        display: "flex",
        alignItems: "center",
    },
    massActionsCheckboxLabel: {
        whiteSpace: "nowrap",
        fontWeight: 700,
        fontSize: "15px",
    },
    massActionsSelectContainer: {
        margin: "0 15px 0 0",
        width: "200px",
    },
    massActionsBtn: {
        minWidth: "auto",
        whiteSpace: "nowrap",
        color: "#fff",
    },
    btnActions: {
        marginLeft: "15px",
    },
    refreshButton: {
        backgroundColor: theme.palette.color2,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.color2,
            color: "#fff",
        }
    },
    button: {
        margin: "0 0 0 5px",
        color: "#fff",
    },
}));

export default useStyles;