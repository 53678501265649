import React from 'react';
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Avatar, Typography} from "@mui/material";
import {VpnLock} from "@mui/icons-material";
import clsx from "clsx";
import useStyles from "./styles";
import {useMount} from "@s-ui/react-hooks";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";

const RecoverPassword = (props) => {
    const {user} = props;

    const classes = useStyles();

    const fields = BASIC_FORM_FIELDS_CONFIG.RECOVER_PASSWORD;

    const handleFormValid = (data) => {
        props.actions.recoverPassword(data);
    }

    useMount(() => {
        props.actions.recoverPasswordInit();
    });

    return (
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <VpnLock className={classes.icon}/>
            </Avatar>

            <Typography component={"h1"} variant={"h5"}>
                Odzyskiwanie hasła
            </Typography>

            {user.profile.recoverPasswordSuccess ? (
                <div className={clsx(classes.message, classes.messageSuccess)}>
                    Sprawdz swoją pocztę! Instrukcja resetowania hasła została wysłana na podany adres email.
                </div>
            ) : (
                <BasicFormBuilderContainer
                    fields={fields}
                    errors={user.profile.recoverPasswordErrors}
                    handleFormValid={handleFormValid}
                    btnText="Odzyskaj hasło"
                />
            )}
        </div>
    );
}

export default RecoverPassword;