import React from 'react';
import {Button, Typography} from "@mui/material";
import useStylesAdBlockDetect from "./styles";

const AdBlockDetect = () => {
    const adBlockDetectStyles = useStylesAdBlockDetect();

    return (
        <div className={adBlockDetectStyles.container}>
            <Typography variant={"h5"} component={"h1"} className={adBlockDetectStyles.title}>
                Wykryto narzędzia blokujące zapytania do serwera
            </Typography>
            
            <ol className={adBlockDetectStyles.informationList}>
                <li className={adBlockDetectStyles.informationListItem}>
                    Do poprawnego działania CRM, <b>wyłącz/wstrzymaj</b> wszystkie narzędzia blokujące dla tej witryny
                    (np. wtyczki AdBlock, uBlock Origin)
                </li>
                <li className={adBlockDetectStyles.informationListItem}>
                    Po wyłączeniu narzedzia&nbsp;
                    <Button
                        onClick={() => window.location.reload()}
                        variant={"outlined"}
                        size={"small"}
                    >
                        Odśwież stronę
                    </Button>
                </li>
            </ol>

            <p className={adBlockDetectStyles.information}>
                Jeśli po przeładowaniu komunikat dalej występuje, temat należy zgłosić do IT.
            </p>
        </div>
    );
}

export default AdBlockDetect;