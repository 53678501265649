import React from 'react';
import Header from "@components/layout/Header/Main";
import {connect} from "react-redux";
import {logout} from "@store/actions/user";
import {bindActionCreators} from "redux";
import {getMenu} from "@store/actions/user/profile/menu";

const HeaderContainer = (props) => {
    return (
        <Header {...props}/>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getMenu,
            logout,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer);