import React from 'react';
import JsonPreviewContainer from "@components/shared/JsonPreview";
import {Paper} from "@mui/material";
import useStyles from "./styles";

const Errors = (props) => {
    const classes = useStyles();

    const {view} = props;

    return (
        <Paper elevation={3} className={classes.paper}>
            <JsonPreviewContainer title="Api Errors" json={view.viewErrors}/>
        </Paper>
    );
}

export default Errors;