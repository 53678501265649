import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    icon: {
        width: "64px",
        height: "64px",
        margin: "30px auto 0 auto",
        display: "table",
        color: theme.palette.color2,
    },
    title: {
        textAlign: "center",
        fontSize: "18px",
        margin: "30px 0",
        fontWeight: 500,
    },
}));

export default useStyles;