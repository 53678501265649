import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {sendFormDataInit, sendFormData} from "@store/actions/apiBuilder/sendFormData";
import {useNavigate} from "react-router";
import {
    createApiFrontUrl,
    getSourceRedirectListUrl,
    redirectToSourceRedirectListUrl
} from "@tools/apiUrl";
import ROUTES from "@consts/routes";
import Form from "./Main";
import {useMount} from "@s-ui/react-hooks";

const FormContainer = (props) => {
    const {apiBuilder} = props;

    const [values, setValues] = useState(apiBuilder.view.viewData.data.values);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [isReadyToRedirect, setIsReadyToRedirect] = useState(false);

    const navigate = useNavigate();

    const handleChangeForm = (inputName, value) => {
        setValues({
            ...values,
            [inputName]: value,
        });
    }

    const handleSubmit = (method, url, redirectUrl) => {
        setRedirectUrl(redirectUrl);

        props.actions.sendFormData(method, url, values);
    }

    useMount(() => {
        props.actions.sendFormDataInit();
    });

    useEffect(() => {
        if (isReadyToRedirect) {
            setIsReadyToRedirect(false);

            navigate(
                redirectToSourceRedirectListUrl(redirectUrl)
                    ? getSourceRedirectListUrl()
                    : createApiFrontUrl({requestUrl: redirectUrl}, true)
            )
        }
    }, [isReadyToRedirect, navigate, redirectUrl]);

    useEffect(() => {
        if (apiBuilder.form.formSent) {
            const responseRedirectUrl = apiBuilder.form.redirectUrl;

            (async () => {
                await props.actions.sendFormDataInit();
                await setRedirectUrl(redirectUrl || responseRedirectUrl || ROUTES.HOME);
                await setIsReadyToRedirect(true);
            })();
        }
    }, [apiBuilder.form.formSent, redirectUrl, apiBuilder.form.redirectUrl, props.actions]);

    return (
        <Form
            config={apiBuilder.view.viewData.data}
            form={apiBuilder.form}
            values={values}
            handleChangeForm={handleChangeForm}
            handleSubmit={handleSubmit}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                sendFormDataInit,
                sendFormData,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer);