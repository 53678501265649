export const LOGIN_USER_INIT = 'LOGIN_USER_INIT';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const AUTO_LOGIN_USER_SUCCESS = 'AUTO_LOGIN_USER_SUCCESS';
export const AUTO_LOGIN_USER_FAILURE = 'AUTO_LOGIN_USER_FAILURE';

export const REFRESH_TOKEN_INIT = 'REFRESH_TOKEN_INIT';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const RECOVER_PASSWORD_INIT = 'RECOVER_PASSWORD_INIT';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_RESET_PASSWORD_TOKEN_INIT = 'GET_RESET_PASSWORD_TOKEN_INIT';
export const GET_RESET_PASSWORD_TOKEN_SUCCESS = 'GET_RESET_PASSWORD_TOKEN_SUCCESS';
export const GET_RESET_PASSWORD_TOKEN_FAILURE = 'GET_RESET_PASSWORD_TOKEN_FAILURE';

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_MENU_INIT = 'GET_MENU_INIT';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';

export const LOGOUT = 'LOGOUT';