import React, {useEffect} from 'react';
import {Tabs, Tab, Grid} from "@mui/material";
import clsx from "clsx";
import FieldsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Fields";
import Row from "@components/views/ApiBuilder/views/shared/Row";
import useStyles from "./styles";
import {v4 as uuidv4} from "uuid";
import {ErrorOutline} from "@mui/icons-material";
import {setParentFieldError} from "@tools/hiddenFormContentErrors";
import ComponentsContainer from "@components/views/ApiBuilder/views/shared/Row/elements/Components";

const TabsContent = (props) => {
    const classes = useStyles();

    const [activeTab, setActiveTab] = React.useState(props.row.options.activeIndex);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const uuid = uuidv4();

    useEffect(() => {
        setParentFieldError("tabContent");
    }, [props.form?.formSentErrors, uuid]);

    return (
        <Grid item xs={12}>
            <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                variant="fullWidth"
                className={clsx(classes.tabs, 'tabs')}
            >
                {props?.row?.cols?.map((col, index) => (
                    <Tab
                        label={col.options.name}
                        key={index}
                        className={classes.tab}
                    />
                ))}
            </Tabs>
            <div className={classes.tabsErrors}>
                {props?.row?.cols?.map((col, index) => (
                    <div className={classes.tabError} id={`tab-error-${uuid}-${index}`} key={index}>
                        <ErrorOutline/> Zakładka zawiera błędy
                    </div>
                ))}
            </div>

            <div className={clsx(classes.tabsContent, 'tabsContent')}>
                {props?.row?.cols?.map((col, index) => (
                    <div className={
                        clsx(
                            classes.tabContent,
                            'tabContent',
                            activeTab === index && classes.tabContentActive
                        )
                    }
                         id={`${uuid}-${index}`}
                         key={index}
                    >
                        {col?.rows?.map((row, index) => (
                            <Row key={index} {...props} row={row}/>
                        ))}

                        {col?.fields && (
                            <FieldsContainer {...props} fields={col.fields}/>
                        )}

                        {col?.components && (
                            <ComponentsContainer {...props} components={col.components}/>
                        )}
                    </div>
                ))}
            </div>
        </Grid>
    );
}

export default TabsContent;