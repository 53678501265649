import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    FormControlLabel,
    InputLabel,
    Select, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {createApiBackendUrl, createApiFrontUrl} from "@tools/apiUrl";
import SCROLL_IDENTIFIERS from "@consts/scrollIdentifiers";
import useStyles from "./styles";
import ACTIONS_TYPES from "@consts/actionsTypes";

const HeaderActions = (props) => {
    const classes = useStyles();

    const {headerActions, itemsLength, total} = props;

    const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState(null);

    const [massActionsValue, setMassActionsValue] = useState("not-selected");

    const updateData = (data) => {
        data['url'] = createApiBackendUrl();

        return data;
    }

    return (
        <div className={classes.headerActions} id={SCROLL_IDENTIFIERS.HEADER_ACTIONS}>
            <div className={classes.leftElements}>
                {/*<FormControlLabel control={<Checkbox />} label={<Typography className={classes.massActionsCheckboxLabel}>Wybierz wszystkie {total}</Typography>}/>*/}
                {/*<FormControl className={classes.massActionsSelectContainer} variant={"outlined"} size={"small"} fullWidth>*/}
                {/*    <InputLabel htmlFor={"test"}>*/}
                {/*        Akcja na wielu elementach*/}
                {/*    </InputLabel>*/}
                {/*    <Select*/}
                {/*        native*/}
                {/*        disabled={false}*/}
                {/*        value={massActionsValue}*/}
                {/*        onChange={event => setMassActionsValue(event.target.value)}*/}
                {/*        label={"Akcja na wielu elementach"}*/}
                {/*        color={"primary"}*/}
                {/*        // inputProps={{*/}
                {/*        //     name: inputName,*/}
                {/*        //     id: inputName,*/}
                {/*        // }}*/}
                {/*    >*/}
                {/*        <option value="not-selected">Wybierz</option>*/}
                {/*        <option value={"1"}>A</option>*/}
                {/*        <option value={"2"}>B</option>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*<Button*/}
                {/*    className={classes.massActionsBtn}*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    disableElevation*/}
                {/*    disabled={massActionsValue === 'not-selected'}*/}
                {/*>*/}
                {/*    Wykonaj na x elementach*/}
                {/*</Button>*/}
            </div>
            <div className={classes.rightElements}>
                <div className={classes.listInformation}>
                    {itemsLength > 0 && <>Wyświetlanych elementów: {itemsLength}/{total}</>}
                </div>
                <div className={classes.btnActions}>
                    <Link to={createApiFrontUrl({page: 1, scrollTo: SCROLL_IDENTIFIERS.HEADER_ACTIONS}, false)}>
                        <Button className={classes.refreshButton}>
                            Odśwież listę
                        </Button>
                    </Link>
                    {headerActions?.map((action, index) => (
                        <React.Fragment key={index}>
                            {action.type === ACTIONS_TYPES.LINK_BUTTON && (
                                <Link to={createApiFrontUrl({requestUrl: action.data.url}, true)}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                    >
                                        {action.data.name}
                                    </Button>
                                </Link>
                            )}
                            {itemsLength > 0 && action.type === ACTIONS_TYPES.EXPORT_BUTTON && (
                                <>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={
                                            (event) => {
                                                event.stopPropagation();
                                                props.actions.sendApiActionInit();

                                                if (action.data.confirmationRequired) {
                                                    setOpenedConfirmDialog(`${action.type}-${index}`);
                                                } else {
                                                    props.actions.sendApiAction(action.data.method, action.data.url, updateData(action.data.data), action.data.successMessage);
                                                }
                                            }
                                        }
                                    >
                                        {action.data.name}
                                    </Button>

                                    {action.data.confirmationRequired && (
                                        <Dialog
                                            open={openedConfirmDialog === `${action.type}-${index}`}
                                            onClose={() => setOpenedConfirmDialog(null)}
                                            aria-labelledby="alert"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            {props.apiBuilder.view.apiActionSentErrors ? (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {props.apiBuilder.view.apiActionSentErrors.length === 1 ? "Wystapił błąd" : "Wystąpiły błędy"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            {props.apiBuilder.view.apiActionSentErrors.map((error, index) => {
                                                                return (
                                                                    <ul key={index}>
                                                                        <li>{error.code}<br/>{error.message}</li>
                                                                    </ul>
                                                                )
                                                            })}
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                () => setOpenedConfirmDialog(null)
                                                            }
                                                            color="primary"
                                                        >
                                                            Wróć do listy
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    window.location.reload(true);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Odśwież stronę
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    props.actions.sendApiAction(action.data.method, action.data.url, updateData(action.data.data), action.data.successMessage);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Spróbuj ponownie
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            ) : (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {action.data.name}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <b><span
                                                                dangerouslySetInnerHTML={{__html: action.data.confirmationTitle}}/></b><br/>
                                                            <span
                                                                dangerouslySetInnerHTML={{__html: action.data.confirmationText}}/>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={
                                                                () => setOpenedConfirmDialog(null)
                                                            }
                                                            color="primary"
                                                        >
                                                            Anuluj
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    props.actions.sendApiAction(action.data.method, action.data.url, updateData(action.data.data), action.data.successMessage);
                                                                }
                                                            }
                                                            color="primary"
                                                        >
                                                            Tak
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </Dialog>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HeaderActions;