import React from 'react';

const LinkComponent = (props) => {
    return (
        <a href={props.component.data.url} target={props.component.data.openInNewWindow && "_blank"} rel="noreferrer">
            {props.component.data.title}
        </a>
    );
}

export default LinkComponent;
