import * as queryString from "query-string";
import ROUTES from "@consts/routes";
import store from "@store/index";

const getStringifiedSearch = (parsedSearch) => {
    const keysOrder = ['requestUrl', 'page', 'size', 'order', 'filter', 'scrollTo', 'rand'];

    return queryString.stringify(parsedSearch, {
        sort: (a, b) => keysOrder.indexOf(a) - keysOrder.indexOf(b),
        encode: false,
        skipNull: true,
    });
}

export const createApiFrontUrl = (keysToUpdate, clearSearch) => {
    const parsedSearch = clearSearch ? {} : queryString.parse(window.location.search);

    const apiViewUrl = ROUTES.API_VIEW;

    Object.entries(keysToUpdate).forEach(([key, value]) => {
        if (key === 'requestUrl') {
            const requestUrlParsed = queryString.parseUrl(value);
            const requestUrlSearch = requestUrlParsed.query;
            const requestUrl = requestUrlParsed.url;

            Object.entries(requestUrlSearch).forEach(([key, value]) => {
                parsedSearch[key] = value;
            });

            parsedSearch['requestUrl'] = requestUrl;
        } else {
            parsedSearch[key] = value;
        }
    });

    if (!keysToUpdate.hasOwnProperty('scrollTo')) {
        delete parsedSearch['scrollTo'];
    }

    parsedSearch['rand'] = Date.now();

    const search = `?${getStringifiedSearch(parsedSearch)}`;

    return `${apiViewUrl}${search}`;
};

export const createApiBackendUrl = () => {
    const parsedSearch = queryString.parse(window.location.search);

    const requestUrl = parsedSearch.requestUrl;

    if (!requestUrl || !requestUrl.includes("http")) {
        return null;
    }

    delete parsedSearch.requestUrl;
    delete parsedSearch.rand;

    const search = getStringifiedSearch(parsedSearch) ? `?${getStringifiedSearch(parsedSearch)}` : '';

    return `${requestUrl}${search}`;
};

export const getSourceRedirectListUrl = () => {
    return store.getState().apiBuilder.view.sourceRedirectListUrl;
}

export const redirectToSourceRedirectListUrl = (newUrl) => {
    return getSourceRedirectListUrl()?.includes(newUrl);
}