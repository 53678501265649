import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    componentRow: {
        marginBottom: "15px",
        position: "relative",
        "&:last-of-type": {
            marginBottom: 0,
        }
    },
    image: {
        maxWidth: "200px",
        maxHeight: "200px",
    }
}));

export default useStyles;