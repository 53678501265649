import React from 'react';

const BulletListComponent = (props) => {
    return (
        <ul>
            {props.component.data.items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
}

export default BulletListComponent;
