import {
    SET_APP_READY,
    SET_PATH_AFTER_LOGIN,
    RESET_PATH_AFTER_LOGIN,
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER
} from "@store/types/config";

const setAppReady = () => ({
    type: SET_APP_READY
});

const setPathAfterLogin = (value) => ({
    type: SET_PATH_AFTER_LOGIN,
    payload: value
});

const resetPathAfterLogin = () => ({
    type: RESET_PATH_AFTER_LOGIN
});


const setMultiLoader = (name) => ({
    type: SET_MULTI_LOADER,
    payload: name
});

const resetMultiLoader = (name) => ({
    type: RESET_MULTI_LOADER,
    payload: name
});

export {
    setAppReady,
    setPathAfterLogin,
    resetPathAfterLogin,
    setMultiLoader,
    resetMultiLoader,
}

