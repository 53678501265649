import React from 'react';
import HeaderActionsContainer from "@components/views/ApiBuilder/views/Grid/HeaderActions";
import PaginationContainer from "@components/views/ApiBuilder/views/Grid/Pagination";
import ListContainer from "@components/views/ApiBuilder/views/Grid/List";
import JsonPreviewContainer from "@components/shared/JsonPreview";
import FilterContainer from "@components/views/ApiBuilder/views/Grid/Filter";
import useStyles from "./styles";

const Grid = (props) => {
    const classes = useStyles();

    const {data} = props.view.viewData;
    const {header, columns, items, filterForm, sorting, pagination} = data;

    return (
        <div className={classes.gridContainer}>
            {filterForm && <FilterContainer {...props} filterForm={filterForm} paginationTotal={pagination?.total}/>}

            <HeaderActionsContainer headerActions={header} itemsLength={items?.length} total={pagination?.total}/>

            <ListContainer columns={columns} items={items} sorting={sorting}/>

            {pagination?.total > 0 && <PaginationContainer pagination={pagination}/>}

            {localStorage.getItem('jsonPreview') && (
                <JsonPreviewContainer title="Api Grid config" json={data}/>
            )}
        </div>
    );
}

export default Grid;