import {
    RECOVER_PASSWORD_INIT,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILURE,

    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    UPDATE_PROFILE_INIT,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,

    CHANGE_PASSWORD_INIT,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,

    GET_RESET_PASSWORD_TOKEN_INIT,
    GET_RESET_PASSWORD_TOKEN_SUCCESS,
    GET_RESET_PASSWORD_TOKEN_FAILURE,
    GET_MENU_INIT,
    GET_MENU_SUCCESS,
    GET_MENU_FAILURE
} from "@store/types/user";

const initialState = {
    getRecoverPasswordTokenSuccess: null,
    getRecoverPasswordTokenErrors: null,

    recoverPasswordSuccess: null,
    recoverPasswordErrors: null,

    resetPasswordSuccess: null,
    resetPasswordErrors: null,

    updateProfileSuccess: null,
    updateProfileErrors: null,

    changePasswordSuccess: null,
    changePasswordErrors: null,

    menuItems: null,
    menuItemsErrors: null,
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESET_PASSWORD_TOKEN_INIT: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: initialState.getRecoverPasswordTokenSuccess,
                getRecoverPasswordTokenErrors: initialState.getRecoverPasswordTokenErrors,
            };
        }
        case GET_RESET_PASSWORD_TOKEN_SUCCESS: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: true,
                getRecoverPasswordTokenErrors: initialState.getRecoverPasswordTokenErrors,
            };
        }
        case GET_RESET_PASSWORD_TOKEN_FAILURE: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: initialState.getRecoverPasswordTokenSuccess,
                getRecoverPasswordTokenErrors: action.payload,
            };
        }

        case RECOVER_PASSWORD_INIT: {
            return {
                ...state,
                recoverPasswordSuccess: initialState.recoverPasswordSuccess,
                recoverPasswordErrors: initialState.recoverPasswordErrors,
            };
        }
        case RECOVER_PASSWORD_SUCCESS: {
            return {
                ...state,
                recoverPasswordSuccess: true,
                recoverPasswordErrors: initialState.recoverPasswordErrors,
            };
        }
        case RECOVER_PASSWORD_FAILURE: {
            return {
                ...state,
                recoverPasswordSuccess: initialState.recoverPasswordSuccess,
                recoverPasswordErrors: action.payload,
            };
        }

        case RESET_PASSWORD_INIT: {
            return {
                ...state,
                resetPasswordSuccess: initialState.resetPasswordSuccess,
                resetPasswordErrors: initialState.resetPasswordErrors,
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                resetPasswordSuccess: true,
                resetPasswordErrors: initialState.resetPasswordErrors,
            };
        }
        case RESET_PASSWORD_FAILURE: {
            return {
                ...state,
                resetPasswordSuccess: initialState.resetPasswordSuccess,
                resetPasswordErrors: action.payload,
            };
        }

        case UPDATE_PROFILE_INIT: {
            return {
                ...state,
                updateProfileSuccess: initialState.updateProfileSuccess,
                updateProfileErrors: initialState.updateProfileErrors,
            };
        }
        case UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                updateProfileSuccess: true,
                updateProfileErrors: initialState.updateProfileErrors,
            };
        }
        case UPDATE_PROFILE_FAILURE: {
            return {
                ...state,
                updateProfileSuccess: initialState.updateProfileSuccess,
                updateProfileErrors: action.payload,
            };
        }

        case CHANGE_PASSWORD_INIT: {
            return {
                ...state,
                changePasswordSuccess: initialState.changePasswordSuccess,
                changePasswordErrors: initialState.changePasswordErrors,
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePasswordSuccess: true,
                changePasswordErrors: initialState.changePasswordErrors,
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                changePasswordSuccess: initialState.changePasswordSuccess,
                changePasswordErrors: action.payload,
            };
        }

        case GET_MENU_INIT: {
            return {
                ...state,
                menuItems: initialState.menuItems,
                menuItemsErrors: initialState.menuItemsErrors,
            };
        }
        case GET_MENU_SUCCESS: {
            return {
                ...state,
                menuItems: action.payload,
                menuItemsErrors: initialState.menuItemsErrors,
            };
        }
        case GET_MENU_FAILURE: {
            return {
                ...state,
                menuItems: initialState.menuItems,
                menuItemsErrors: action.payload,
            };
        }
        default:
            return state;
    }
}

export default profileReducer;


