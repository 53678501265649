import React from 'react';
import {EmojiPeople} from "@mui/icons-material";
import {Container} from "@mui/material";
import useStyles from "./styles";

const StartPage = (props) => {
    const classes = useStyles();

    const {firstName} = props.user.auth.userData;

    return (
        <Container>
            <EmojiPeople className={classes.icon}/>
            <h1 className={classes.title}>
                Witaj {firstName}. Wybierz pozycję z Menu
            </h1>

            <p>
                To <b>rozwojowa</b> wersja Edytora. Edytor to zbiór modułów, grup i elementów. Każda strona generowana
                jest na
                określonych z góry wzorcach, dlatego widoki list i&nbsp;formularzy wyglądają analogicznie.
                W&nbsp;przypadku
                jakichkolwiek błędów działania lub wyświetlania danych, prosimy o informację w ClickUp ze screenem
                i&nbsp;opisem na jakim URL występują.
            </p>
        </Container>
    );
}

export default StartPage;