import React from 'react';
import Grid from "./Main";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const GridContainer = (props) => {
    return (
        <Grid view={props.apiBuilder.view}/>
    );
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GridContainer);