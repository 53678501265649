import React from 'react';
import Filter from "./Main";

const FilterContainer = (props) => {
    return (
        <Filter
            {...props}
            config={props.filterForm}
        />
    );
}

export default FilterContainer;