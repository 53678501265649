import React from "react";
import Layout from "@components/layout/Layout/Main";

const LayoutContainer = () => {
    return (
        <Layout/>
    )
}

export default LayoutContainer;

