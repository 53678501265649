import {
    SET_APP_READY,
    SET_PATH_AFTER_LOGIN,
    RESET_PATH_AFTER_LOGIN,
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER
} from "@store/types/config";

import ROUTES from "@consts/routes";

const initialState = {
    appReady: false,
    pathAfterLogin: ROUTES.HOME,
    multiLoader: [],
}

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_READY:
            return {
                ...state,
                appReady: true,
            };
        case SET_PATH_AFTER_LOGIN:
            return {
                ...state,
                pathAfterLogin: action.payload
            };
        case RESET_PATH_AFTER_LOGIN:
            return {
                ...state,
                pathAfterLogin: initialState.pathAfterLogin
            };
        case SET_MULTI_LOADER:
            const found = state.multiLoader.find(item => item.name === action.payload);

            if(found) {
                return {
                    ...state,
                    multiLoader: state.multiLoader.map(item => {
                        if (item.name === action.payload) {
                            return {
                                ...item,
                                isActive: true
                            }
                        }
                        return item;
                    }),
                };
            } else {
                return {
                    ...state,
                    multiLoader: [
                        ...state.multiLoader,
                        {
                            name: action.payload,
                            isActive: true,
                        }
                    ]
                };
            }

        case RESET_MULTI_LOADER:
            return {
                ...state,
                multiLoader: state.multiLoader.map(item => {
                    if (item.name === action.payload) {
                        return {
                            ...item,
                            isActive: false
                        }
                    }
                    return item;
                }),
            };
        default:
            return state;
    }
}

export default configReducer;