import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_PLACES from "@consts/loaderPlaces";

const LOADER_CONFIG = [
    {
        name: LOADER_PLACES.GET_MENU,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.LOGIN,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.AUTOLOGIN,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.LOGOUT,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.RECOVER_PASSWORD,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.RESET_PASSWORD,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.RESET_PASSWORD_CHECK_TOKEN,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.UPDATE_PROFILE,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.CHANGE_PASSWORD,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.GET_AUTOCOMPLETE,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.GET_VIEW,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.SEND_FORM_DATA,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.SEND_API_ACTION,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
]

export default LOADER_CONFIG;
