import React from 'react';
import {ErrorOutline} from "@mui/icons-material";
import useStyles from "./styles";
import clsx from "clsx";

const FieldError = (props) => {
    const classes = useStyles();

    return (
        <>
            {props.fieldName && props.errors?.filter((error => error.context === props.fieldName)).map((type, index) => (
                <div className={clsx(classes.fieldError, 'fieldError')} key={index}>
                    <ErrorOutline/> {type.message}
                </div>
            ))}
        </>
    );
}

export default FieldError;