import React, {useState} from 'react';
import {Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

const MultiSelectInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const classes = useStyles();

    return (
        <>
            <FormControl variant={"outlined"} size={"small"} fullWidth>
                <InputLabel id={`label-${inputName}`}>
                    {createInputLabel(props.field.label, props.field.required)}
                </InputLabel>
                <Select
                    labelId={`label-${inputName}`}
                    id={inputName}
                    name={inputName}
                    disabled={props.field.options.disabled}
                    multiple
                    color={"primary"}
                    value={value}
                    onChange={event => {
                        setValue(event.target.value);
                        setGlobalFormFieldValue(inputName, event.target.value, props);
                    }}
                    input={<OutlinedInput label={createInputLabel(props.field.label, props.field.required)}/>}
                    renderValue={(selected) => (
                        <div>
                            {selected.map((value, index) => (
                                <React.Fragment key={index}>
                                    {props.field.choices.filter(choice => choice.value === value).map(choice => (
                                        <Chip
                                            size={"small"}
                                            key={index}
                                            label={choice.name}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                >
                    {props.field.choices.map((choice, index) => (
                        <MenuItem key={index} value={choice.value}>
                            {choice.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {(value?.length > 0 && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue([]);
                            setGlobalFormFieldValue(inputName, [], props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default MultiSelectInput;