import React from 'react';
import {Container} from "@mui/material";
import useStyles from "./styles";

const Main = (props) => {
    const classes = useStyles();

    return (
        <main className={classes.main}>
            <Container maxWidth={false}>
                <props.content {...props} />
            </Container>
        </main>
    );
}

export default Main;