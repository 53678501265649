import React, {useEffect, useState} from 'react';
import HeaderContainer from "@components/layout/Header";
import MainContainer from "@components/layout/Main";
import {Navigate, useLocation, useNavigate, useParams} from "react-router";
import ROUTES from "@consts/routes";
import {useMount} from "@s-ui/react-hooks";
import PAGES from "@consts/pages";

const Authorization = (props) => {
    const [isAuthReady, setIsAuthReady] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    useMount(async () => {
        window.scrollTo(0, 0);

        await initAuthorization();
    });

    useEffect(() => {
        if (props.routeConfig.name !== PAGES.API_VIEW) {
            props.actions.getViewInit();
        }
    });

    const initAuthorization = async () => {
        const {user, routeConfig} = props;

        if (user.auth.isLoggedIn && routeConfig.logoutBeforeMount) {
            await props.actions.logout();
        }

        if (!user.auth.isLoggedIn) {
            if (routeConfig.name !== PAGES.LOGIN) {
                props.actions.resetPathAfterLogin();
            }

            if (routeConfig.authRequired) {
                props.actions.setPathAfterLogin(location.pathname + location.search);
            }
        }

        setIsAuthReady(true);
    }

    return (
        <>
            {isAuthReady && (
                <>
                    {!props.user.auth.isLoggedIn && props.routeConfig.authRequired
                        ? <Navigate to={ROUTES.LOGIN}/>
                        : <>
                            <HeaderContainer routeConfig={props.routeConfig}/>
                            <MainContainer
                                {...props} //toDo delete after refactor ?
                                navigate={navigate} //toDo delete after refactor
                                location={location} //toDo delete after refactor
                                params={params} //toDo delete after refactor
                                content={props.routeConfig.component}
                            />
                        </>
                    }
                </>
            )}
        </>
    );
}

export default Authorization;