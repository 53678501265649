import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    form: {
        margin: "30px 0 0 0",
        width: "100%",
    },
    fieldRow: {
        width: "100%",
        margin: "0 0 15px 0",
        position: "relative",
    },
    textField: {
        margin: 0,
    },
    labelParagraph: {
        margin: 0,
    },
    fieldError: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    serverErrors: {
        margin: "0 0 15px 0",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.color7,
    },
    serverError: {
        fontSize: "13px",
        fontWeight: 600,
        color: "#fff",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    submitBtn: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        width: "100%",
        height: "50px",
        textTransform: "uppercase",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
}));

export default useStyles;