const ROUTES = {
    HOME: '/',
    LOGIN: '/logowanie',
    RECOVER_PASSWORD: '/odzyskaj-haslo',
    RESET_PASSWORD: '/zmien-haslo/:resetPasswordToken',
    API_VIEW: '/apiView',
    NOT_FOUND: "*",
};

export default ROUTES;
