import React, {useState} from 'react';
import {Checkbox, FormControlLabel, FormGroup, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

function MultiCheckboxInput(props) {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const handleChangeMultiCheckbox = (newValue) => {
        let value = inputValue;

        if (value.includes(newValue)) {
            value = value.filter(item => item !== newValue);
        } else {
            value = [...value, newValue];
        }

        setValue(value);
        setGlobalFormFieldValue(inputName, value, props);
    }

    const classes = useStyles();

    return (
        <>
            <OutlinedDiv label={createInputLabel(props.field.label, props.field.required)}>
                <FormGroup
                    className={props.field.options?.layout_type === "horizontal" ? classes.inlineMultiCheckboxFormGroup : ""}>
                    {props.field.choices.map(choice => (
                        <FormControlLabel
                            key={choice.value}
                            control={
                                <Checkbox
                                    size={"small"}
                                    color={"primary"}
                                    checked={value.includes(choice.value)}
                                    readOnly={props.field.attributes.readonly}
                                    disabled={props.field.options.disabled}
                                    onChange={event => handleChangeMultiCheckbox(event.target.value)}
                                    name={inputName}
                                    value={choice.value}
                                />
                            }
                            label={choice.name}
                            className={props.field.options?.layout_type === "horizontal" ? classes.inlineMultiCheckboxLabel : ""}
                        />
                    ))}
                </FormGroup>
            </OutlinedDiv>

            {(value?.length > 0 && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setValue([]);
                            setGlobalFormFieldValue(inputName, [], props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default MultiCheckboxInput;