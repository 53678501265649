import React from 'react';
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Avatar, Typography} from "@mui/material";
import {People} from "@mui/icons-material";
import useStyles from "./styles";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";
import {useNavigate} from "react-router";

const Login = (props) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const fields = BASIC_FORM_FIELDS_CONFIG.LOGIN;

    const handleFormValid = (data) => {
        props.actions.login(data, navigate);
    }

    useMount(() => {
        props.actions.loginInit();
    });

    return (
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <People className={classes.icon}/>
            </Avatar>

            <Typography component={"h1"} variant={"h5"}>
                Logowanie
            </Typography>

            <BasicFormBuilderContainer
                fields={fields}
                errors={props.user.auth.errors}
                handleFormValid={handleFormValid}
                btnText="Zaloguj się"
            />

            <p className={classes.paragraph}>
                Nie pamiętasz hasła? <Link to={ROUTES.RECOVER_PASSWORD}>Odzyskaj hasło</Link>
            </p>
        </div>
    );
}

export default Login;