import {combineReducers} from "redux";
import configReducer from "@store/reducers/config";
import authReducer from "@store/reducers/user/auth";
import profileReducer from "@store/reducers/user/profile";
import viewReducer from "@store/reducers/apiBuilder/view";
import formReducer from "@store/reducers/apiBuilder/form";

const rootReducer = combineReducers({
    config: configReducer,
    user: combineReducers({
        auth: authReducer,
        profile: profileReducer,
    }),
    apiBuilder: combineReducers({
        view: viewReducer,
        form: formReducer,
    }),
})

export default rootReducer;