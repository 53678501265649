import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: "15px 0 45px 0",
        padding: "15px",
    },
    formHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.color4}`,
        marginBottom: "15px",
        padding: "0 0 10px 0",
    },
    form: {
        display: "table",
        width: "100%",
    },
    clearFormLink: {
        color: "#000",
    },
    clearFormButton: {
        textTransform: "none",
    },
    formTitle: {
        fontSize: "18px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
    },
}));

export default useStyles;