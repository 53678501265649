import React from 'react';
import ResetPassword from "@components/views/ResetPassword/Main";
import {connect} from "react-redux";
import {getResetPasswordToken} from "@store/actions/user";
import {resetPassword} from "@store/actions/user";
import {bindActionCreators} from "redux";

const ResetPasswordContainer = (props) => {
    return (
        <ResetPassword {...props}/>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getResetPasswordToken,
            resetPassword,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer);