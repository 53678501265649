import React, {useEffect, useState} from 'react';
import {TablePagination} from "@mui/material";
import useStyles from "./styles";
import {createApiFrontUrl} from "@tools/apiUrl";
import SCROLL_IDENTIFIERS from "@consts/scrollIdentifiers";
import {useNavigate} from "react-router";

const Pagination = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {pagination} = props;

    const [redirectUrl, setRedirectUrl] = useState(null);

    const buildSearchPart = (keysToUpdate) => {
        const url = createApiFrontUrl(keysToUpdate, false);

        setRedirectUrl(url);
    }

    const handlePageChange = (event, newPage) => {
        const keysToUpdate = {
            page: newPage + 1,
            scrollTo: SCROLL_IDENTIFIERS.HEADER_ACTIONS,
        };

        buildSearchPart(keysToUpdate);
    }

    const handleChangeRowsPerPage = (event) => {
        const keysToUpdate = {
            size: event.target.value,
            page: 1,
            scrollTo: SCROLL_IDENTIFIERS.HEADER_ACTIONS,
        };

        buildSearchPart(keysToUpdate);
    }

    useEffect(() => {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
    }, [redirectUrl, navigate]);

    return (
        <div className={classes.paginationContainer}>
            <TablePagination
                labelDisplayedRows={({from, to, count}) => `${from}-${to} z ${count}`}
                labelRowsPerPage='Wyświetl max'
                rowsPerPageOptions={[10, 50, 100, 200]}
                component="div"
                count={pagination.total}
                rowsPerPage={pagination.perPage}
                page={pagination.currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Pagination;
