import {
    SEND_FORM_DATA_INIT,
    SEND_FORM_DATA_SUCCESS,
    SEND_FORM_DATA_FAILURE,
} from "@store/types/apiBuilder";

import {client} from "@api/client";
import {getAccessToken} from "@tools/tokens";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendFormDataInit = () => ({
    type: SEND_FORM_DATA_INIT,
});

const sendFormDataSuccess = (data) => ({
    type: SEND_FORM_DATA_SUCCESS,
    payload: data
});

const sendFormDataFailure = (errors) => ({
    type: SEND_FORM_DATA_FAILURE,
    payload: errors
});

const sendFormData = (method, action, data) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.SEND_API_ACTION));
        await dispatch(sendFormDataInit());

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client(method, action, data, headers);
            const responseData = response.data.data;

            const {redirectUrl} = responseData;

            await dispatch(sendFormDataSuccess(redirectUrl));
        } catch (error) {
            if (error.response) {
                await dispatch(sendFormDataFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.SEND_API_ACTION));
    }
}

export {
    sendFormDataInit,
    sendFormData,
};