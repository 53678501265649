import React from 'react';
import {
    Box,
    Grid, Typography,
} from "@mui/material";
import TabsContentContainer from "@components/views/ApiBuilder/views/shared/Row/contentTypes/TabsContent";
import NormalContentContainer from "@components/views/ApiBuilder/views/shared/Row/contentTypes/NormalContent";
import BlockContentContainer from "@components/views/ApiBuilder/views/shared/Row/contentTypes/BlockContent";
import CollapseContentContainer
    from "@components/views/ApiBuilder/views/shared/Row/contentTypes/CollapseContent";
import {createInputPath} from "@tools/fields";
import useStyles from "./styles";

const Row = (props) => {
    const classes = useStyles();

    return (
        <Box sx={{ flexGrow: 1 }} mt={2} className={classes.box}>
            <Grid container spacing={2}>
                {(props?.row?.options.name || props?.row?.options.description) && (
                    <Grid item xs={12}>
                        {props?.row?.options.name && (
                            <Typography variant="subtitle1" className={classes.title}>
                                {props?.row?.options.name}
                            </Typography>
                        )}
                        {props?.row?.options.description && (
                            <Typography variant="subtitle2" className={classes.description}>
                                {props.row.options.description}
                            </Typography>
                        )}
                    </Grid>
                )}
                {props?.row?.options?.type === "NORMAL" && (
                    <NormalContentContainer {...props} row={props.row} path={createInputPath(props)}/>
                )}
                {props?.row?.options?.type === "BLOCK" && (
                    <BlockContentContainer {...props} row={props.row} path={createInputPath(props)}/>
                )}
                {props?.row?.options?.type === "COLLAPSE" && (
                    <CollapseContentContainer {...props} row={props.row} path={createInputPath(props)}/>
                )}
                {props?.row?.options?.type === "TAB" && (
                    <TabsContentContainer {...props} row={props.row} path={createInputPath(props)}/>
                )}
            </Grid>
        </Box>
    );
}

export default Row;