import React, {useEffect, useState} from 'react';
import useStylesInternetConnection from "./styles";
import {Cancel, Wifi, WifiOff} from "@mui/icons-material";

const InternetConnection = () => {
    const internetConnectionStyles = useStylesInternetConnection();

    const [connectionStatus, setConnectionStatus] = useState(null);

    const handleReload = () => {
        window.location.reload(true);
    }

    useEffect(() => {
        window.addEventListener('online', () => {
            setConnectionStatus("online");
        });
        window.addEventListener('offline', () => {
            setConnectionStatus("offline");
        });
    });

    return (
        <>
            {connectionStatus && (
                <div className={internetConnectionStyles.internetConnection}>
                    <div
                        className={internetConnectionStyles.message}
                    >
                        {connectionStatus === "online" ? (
                            <Wifi className={internetConnectionStyles.messageIconOnline}/>
                        ) : (
                            <WifiOff className={internetConnectionStyles.messageIconOffline}/>
                        )}
                        <span className={internetConnectionStyles.text}>
                            {connectionStatus === "online"
                                ? "Połączono z Internetem."
                                : "Brak połączenia z Internetem."
                            }
                        </span>
                        <span
                            className={internetConnectionStyles.refreshText}
                            onClick={handleReload}>
                            Odśwież
                        </span>
                        {connectionStatus === "online" && (
                            <Cancel
                                className={internetConnectionStyles.closeButton}
                                onClick={() => setConnectionStatus(null)}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default InternetConnection;