import React, {useState} from 'react';
import { Autocomplete } from '@mui/material';
import {TextField, Tooltip} from "@mui/material";
import {useDebouncedCallback} from "use-debounce";
import {bindActionCreators} from "redux";
import {getAutocomplete, getAutocompleteInit} from "@store/actions/apiBuilder/getAutocomplete";
import {connect} from "react-redux";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import {Clear} from "@mui/icons-material";
import useStyles from "../styles";

const AutocompleteInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    const [inputValueLength, setInputValueLength] = useState(0);

    const [value, setValue] = useState(inputValue);

    useMount(() => {
        setGlobalFormFieldValue(inputName, value, props);
    })

    const buildAutocompleteUrl = (value, url) => {
        return url.replace("%25QUERY%25", value);
    }

    const getOptionsText = () => {
        const options = props.apiBuilder.form.autocompleteOptions[props.field.name];
        const errors = props.apiBuilder.form.autocompleteOptionsErrors[props.field.name];

        if (inputValueLength >= 2 && !options && !errors) {
            return "Wyszukiwanie...";
        } else if (options?.length >= 0) {
            return "Brak wyników";
        } else if (errors?.length > 0) {
            return "Wystąpił błąd";
        } else {
            return props.field.attributes.placeholder;
        }
    }

    const getOptions = () => {
        const value = inputValue;
        const results = props.apiBuilder.form.autocompleteOptions[props.field.name];

        let newOptions = [];

        if (props.field.type === "multiautocomplete" && value?.length > 0) {
            newOptions = value;
        }

        if (results) {
            newOptions = [...newOptions, ...results];
        }

        return newOptions;
    }

    const debounced = useDebouncedCallback(
        (value) => {
            if (value.length >= 2) {
                const newUrl = buildAutocompleteUrl(value, props.field.options.url);
                props.actions.getAutocomplete(props.field.name, newUrl);
            } else {
                props.actions.getAutocompleteInit(props.field.name);
            }
        }, 1000
    );

    const handleChangeInput = (value) => {
        setInputValueLength(value.length);

        debounced(value);
    }

    const classes = useStyles();

    return <>
        <Autocomplete
            size={"small"}
            clearOnBlur
            clearIcon={null}
            multiple={props.field.type === "multiautocomplete"}
            filterSelectedOptions={props.field.type === "multiautocomplete"}
            onOpen={() => {
                props.actions.getAutocompleteInit(props.field.name);

                if (props.field.type === "autocomplete" && inputValue) {
                    handleChangeInput(inputValue.name);
                }
            }}
            noOptionsText={getOptionsText()}
            fullWidth
            id={inputName}
            name={inputName}
            readOnly={props.field.attributes.readonly}
            disabled={props.field.options.disabled}
            type={props.field.type}
            options={getOptions()}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => {
                setValue(value);
                setGlobalFormFieldValue(inputName, value, props);
            }}
            value={value}
            onInputChange={(event, inputValue) => {
                event?.type === "change" && handleChangeInput(inputValue);
            }}
            renderInput={
                params => (
                    <TextField
                        {...params}
                        label={createInputLabel(props.field.label, props.field.required)}
                        variant="outlined"
                    />
                )
            }
        />

        {props.field.type === "multiautocomplete" ? (
            <>
                {(value?.length > 0 && !props.field.attributes.readonly && !props.field.options.disabled) && (
                    <Tooltip title="Wyczyść wartość" arrow>
                        <Clear className={classes.clearIcon} onClick={
                            () => {
                                setValue([]);
                                setGlobalFormFieldValue(inputName, [], props);
                            }
                        }/>
                    </Tooltip>
                )}
            </>
        ) : (
            <>
                {(value && !props.field.attributes.readonly && !props.field.options.disabled) && (
                    <Tooltip title="Wyczyść wartość" arrow>
                        <Clear className={classes.clearIcon} onClick={
                            () => {
                                setValue(null);
                                setGlobalFormFieldValue(inputName, null, props);
                            }
                        }/>
                    </Tooltip>
                )}
            </>
        )}
    </>;
}

const mapStateToProps = (state) => {
    return {
        apiBuilder: state.apiBuilder,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                getAutocompleteInit,
                getAutocomplete,
            },
            dispatch,
        ),
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutocompleteInput);
