const PAGES = {
    HOME: 'HOME',
    LOGIN: 'LOGIN',
    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    API_VIEW: 'API_VIEW',
    NOT_FOUND: 'NOT_FOUND',
};

export default PAGES;
