import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    fieldError: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
}));

export default useStyles;