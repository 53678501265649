import {
    GET_RESET_PASSWORD_TOKEN_INIT,
    GET_RESET_PASSWORD_TOKEN_SUCCESS,
    GET_RESET_PASSWORD_TOKEN_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {resetMultiLoader, setMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getResetPasswordTokenInit = () => ({
    type: GET_RESET_PASSWORD_TOKEN_INIT
});

const getResetPasswordTokenSuccess = () => ({
    type: GET_RESET_PASSWORD_TOKEN_SUCCESS
});

const getResetPasswordTokenFailure = (errors) => ({
    type: GET_RESET_PASSWORD_TOKEN_FAILURE,
    payload: errors
});

const getResetPasswordToken = (token) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.RESET_PASSWORD_CHECK_TOKEN));
        await dispatch(getResetPasswordTokenInit());

        const path = `/api/reset-password/token/${token}`;
        const url = (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path;

        const headers = {};

        try {
            const response = await client('GET', url, null, headers);
            const responseData = response.data.data;

            await dispatch(getResetPasswordTokenSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getResetPasswordTokenFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.RESET_PASSWORD_CHECK_TOKEN));
    }
}
export {
    getResetPasswordToken,
};