import React from 'react';
import Row from "@components/views/ApiBuilder/views/shared/Row";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";
import {makeStyles} from "@mui/styles";

const SubformInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const label = createInputLabel(props.field.label, props.field.required);

    useMount(() => {
        const value = getGlobalFormFieldValue(inputName, props);
        if (!value || Array.isArray(value)) { //if null or array
            setGlobalFormFieldValue(inputName, {}, props); //set subform default value as object, to set sub inputs values
        }
    })

    const handleChangeSubform = (key, value) => {
        let mainSubformValue = getGlobalFormFieldValue(inputName, props);

        mainSubformValue[key] = value;

        setGlobalFormFieldValue(inputName, mainSubformValue, props);
    }

    const useStyles = makeStyles(theme => ({
        contentContainer: {
            padding: "15px 5px",
        },
    }));

    const classes = useStyles();

    return (
        <>
            {getGlobalFormFieldValue(inputName, props) && (
                <OutlinedDiv label={label}>
                    <div className={classes.contentContainer}>
                        {props.field.elements.rows.map((row, index) => (
                            <Row
                                {...props}
                                row={row}
                                parentType={"subform"}
                                parentName={props.field.name}
                                key={index}
                                handleChangeSubform={handleChangeSubform}
                            />
                        ))}
                    </div>
                </OutlinedDiv>
            )}
        </>
    );
}

export default SubformInput;