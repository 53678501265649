import React from 'react';
import {Typography} from "@mui/material";

const NotFoundPage = () => {
    return (
        <Typography component={"h1"} variant={"h1"} align={"center"}>
            404
        </Typography>
    );
}

export default NotFoundPage;