export const GET_API_VIEW_INIT = 'GET_API_VIEW_INIT';
export const GET_API_VIEW_SUCCESS = 'GET_API_VIEW_SUCCESS';
export const GET_API_VIEW_FAILURE = 'GET_API_VIEW_FAILURE';

export const SEND_FORM_DATA_INIT = 'SEND_FORM_DATA_INIT';
export const SEND_FORM_DATA_SUCCESS = 'SEND_FORM_DATA_SUCCESS';
export const SEND_FORM_DATA_FAILURE = 'SEND_FORM_DATA_FAILURE';

export const SEND_API_ACTION_INIT = 'SEND_API_ACTION_INIT';
export const SEND_API_ACTION_SUCCESS = 'SEND_API_ACTION_SUCCESS';
export const SEND_API_ACTION_FAILURE = 'SEND_API_ACTION_FAILURE';

export const SET_SOURCE_REDIRECT_LIST_URL = 'SET_SOURCE_REDIRECT_LIST_URL';

export const GET_AUTOCOMPLETE_INIT = 'GET_AUTOCOMPLETE_INIT';
export const GET_AUTOCOMPLETE_SUCCESS = 'GET_AUTOCOMPLETE_SUCCESS';
export const GET_AUTOCOMPLETE_FAILURE = 'GET_AUTOCOMPLETE_FAILURE';