import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import {CircularProgress, Tooltip} from "@mui/material";
import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_CONFIG from "@consts/loaderConfig";
import useStyles from "./styles";

const Loader = (props) => {
    const classes = useStyles();

    const [showWait, setShowWaitInfo] = useState(false);
    const [waitInfoPosition, setWaitInfoPosition] = useState({});

    const runWaitInfo = (event) => {
        setShowWaitInfo(true);
        setWaitInfoPosition({left: `${event.clientX}px`, top: `${event.clientY}px`});
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWaitInfo(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, [showWait]);

    const WaitInfo = () => {
        return (
            <Tooltip title="Proszę czekać, trwa zapytanie" open arrow placement="top">
                <span className={classes.waitInfoContainer} style={waitInfoPosition}/>
            </Tooltip>
        )
    }

    const ShowLoader = () => {
        const loaderConfig = LOADER_CONFIG.find(item => item.name === props.name);

        if (loaderConfig.type === LOADER_TYPES.FULLSCREEN_TRANSPARENT) {
            return (
                <div className={clsx(
                    classes.fullScreenTransparentLoader,
                    props.isActive && classes.fullScreenTransparentLoaderActive
                )}
                     onClick={event => runWaitInfo(event)}
                >
                    <CircularProgress className={classes.loader}/>
                    {showWait && <WaitInfo/>}
                </div>
            )
        } else if (loaderConfig.type === LOADER_TYPES.FULLSCREEN_FOR_SKELETON) {
            return (
                <div className={clsx(
                    classes.fullScreenForSkeletonLoader,
                    props.isActive && classes.fullScreenForSkeletonLoaderActive
                )}>
                    {showWait && <WaitInfo/>}
                </div>
            )
        } else {
            return (
                <div className={clsx(
                    classes.fullScreenLoader,
                    props.isActive && classes.fullScreenLoaderActive
                )}>
                    <CircularProgress className={classes.loader}/>
                    {showWait && <WaitInfo/>}
                </div>
            )
        }
    }

    return (
        <ShowLoader/>
    );
}

export default Loader;