import React from 'react';
import {ErrorOutline} from "@mui/icons-material";
import {Grid, Typography} from "@mui/material";
import useStyles from "./styles";

const FormErrors = (props) => {
    const classes = useStyles();

    return (
        <>
            {props.errors && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.serverErrors}>
                            {props.errors?.filter(error => error.code === "FORM_FIELD_ERROR")?.length > 0 && (
                                <Typography component={"h6"} className={classes.title}>
                                    Formularz zawiera błędy. Popraw i spróbuj ponownie.
                                </Typography>
                            )}

                            {props.errors?.filter(error => error.code !== "FORM_FIELD_ERROR")?.length > 0 && (
                                <>
                                    <Typography component={"h6"} className={classes.title}>
                                        Poważne błędy przy zapisie (zgłoś IT):
                                    </Typography>

                                    {props.errors?.filter(error => error.code !== "FORM_FIELD_ERROR").map((error, index) => (
                                        <div className={classes.error} key={index}>
                                            <ErrorOutline/> {error.code} {error.context && <> - {error.context}</>} {error.message && <> - {error.message}</>}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default FormErrors;