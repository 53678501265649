import React from 'react';
import useStyles from "./styles";
import ImageComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/ImageComponent";
import ParagraphComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/ParagraphComponent";
import LinkComponent from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/LinkComponent";
import HeaderComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/HeaderComponent";
import TableComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/TableComponent";
import NumberedListComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/NumberedListComponent";
import BulletListComponent
    from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/BulletListComponent";
import HtmlComponent from "@components/views/ApiBuilder/views/shared/Row/elements/Components/componentsTypes/HtmlComponent";

const Components = (props) => {
    const classes = useStyles();

    const componentsConfig = [
        {type: "paragraph", component: ParagraphComponent},
        {type: "html", component: HtmlComponent},
        {type: "link", component: LinkComponent},
        {type: "image", component: ImageComponent},
        {type: "header", component: HeaderComponent},
        {type: "table", component: TableComponent},
        {type: "numbered-list", component: NumberedListComponent},
        {type: "bulled-list", component: BulletListComponent},
    ];

    return (
        <>
            {props?.components?.map((component, index) => (
                <React.Fragment key={index}>
                    {componentsConfig.filter(componentConfig => componentConfig.type === component.type).map((componentConfig, index) => (
                        <div className={classes.componentRow} key={index}>
                            <componentConfig.component {...props} component={component}/>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </>
    );
}

export default Components;
