import React from 'react';
import {Checkbox, FormControlLabel, Tooltip} from "@mui/material";
import {
    createInputLabel,
    createInputName,
    getGlobalFormFieldValue,
    setGlobalFormFieldValue
} from "@tools/fields";
import {useMount} from "@s-ui/react-hooks";
import OutlinedDiv from "@components/views/ApiBuilder/views/shared/Row/elements/Fields/fieldTypes/OutlinedDiv";
import useStyles from "../styles";
import {Clear} from "@mui/icons-material";

const CheckboxInput = (props) => {
    const inputName = createInputName(props.field.name, props);
    const inputValue = getGlobalFormFieldValue(inputName, props);

    useMount(() => {
        setGlobalFormFieldValue(inputName, inputValue, props);
    })

    const classes = useStyles();

    return (
        <>
            <OutlinedDiv label={createInputLabel(props.field.label, props.field.required)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{paddingTop: 0, paddingBottom: 0}}
                            readOnly={props.field.attributes.readonly}
                            disabled={props.field.options.disabled}
                            id={inputName}
                            name={inputName}
                            checked={inputValue}
                            color={"primary"}
                            value={inputValue}
                            onChange={event => {
                                setGlobalFormFieldValue(inputName, event.target.checked, props);
                            }}
                        />
                    }
                    label="Tak"
                />
            </OutlinedDiv>

            {(inputValue && !props.field.attributes.readonly && !props.field.options.disabled) && (
                <Tooltip title="Wyczyść wartość" arrow>
                    <Clear className={classes.clearIcon} onClick={
                        () => {
                            setGlobalFormFieldValue(inputName, null, props);
                        }
                    }/>
                </Tooltip>
            )}
        </>
    );
}

export default CheckboxInput;
