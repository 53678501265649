import React from 'react';
import {Typography} from "@mui/material";

const HeaderComponent = (props) => {
    return (
        <Typography component={`h${props.component.data.size}`}>
            {props.component.data.text}
        </Typography>
    );
}

export default HeaderComponent;
