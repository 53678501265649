const PAGE_META_DETAILS = {
    HOME: {
        title: "Strona główna",
        description: "",
    },
    LOGIN: {
        title: "Logowanie",
        description: "",
    },
    RECOVER_PASSWORD: {
        title: "Odzyskiwanie hasła",
        description: "",
    },
    RESET_PASSWORD: {
        title: "Resetowanie hasła",
        description: "",
    },
    NOT_FOUND: {
        title: "Błedny adres",
        description: "",
    },
};

export default PAGE_META_DETAILS;