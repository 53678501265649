import React, {useEffect, useState} from 'react';
import {Button, Paper} from "@mui/material";
import Row from "@components/views/ApiBuilder/views/shared/Row";
import {Search} from "@mui/icons-material";
import {createApiFrontUrl} from "@tools/apiUrl";
import {Link} from "react-router-dom";
import * as queryString from "query-string";
import useStyles from "./styles";
import {usePrevious} from "@s-ui/react-hooks";
import {useNavigate} from "react-router";
import SCROLL_IDENTIFIERS from "@consts/scrollIdentifiers";

const Filter = (props) => {
    const classes = useStyles();

    const parsedSearch = queryString.parse(window.location.search);
    const isFilterParamExist = parsedSearch.filter;

    const [filterValues, setFilterValues] = useState(props.filterForm.values);
    const prevFilterValues = usePrevious(filterValues);

    const navigate = useNavigate();

    useEffect(() => {
        const stringifiedFilterValues = JSON.stringify(filterValues);
        const stringifiedPrevFilterValues = JSON.stringify(prevFilterValues);

        if (prevFilterValues && (stringifiedFilterValues !== stringifiedPrevFilterValues)) {
            const redirectUrl = createApiFrontUrl({
                filter: stringifiedFilterValues,
                page: 1,
                scrollTo: SCROLL_IDENTIFIERS.DISABLED
            }, false);

            navigate(redirectUrl);
        }
    });

    const handleChangeForm = (key, value) => {
        setFilterValues({
            ...filterValues,
            [key]: value,
        });
    }
    
    return (
        <Paper elevation={3} className={classes.paper}>
            <div className={classes.formHeader}>
                <div className={classes.formTitle}><Search/> Wyszukiwarka (znaleziono: {props.paginationTotal})</div>
                {isFilterParamExist && (
                    <Link
                        to={createApiFrontUrl({filter: null}, false)}
                        className={classes.clearFormLink}
                    >
                        <Button className={classes.clearFormButton} size={"small"}>
                            Wyczyść formularz
                        </Button>
                    </Link>
                )}
            </div>

            <form
                className={classes.form}
                name={props.filterForm.name}
                onSubmit={event => event.preventDefault()}
                noValidate
                autoComplete="off"
            >
                {props.filterForm.elements.rows.map((row, index) => (
                    <Row key={index} {...props} row={row} handleChangeForm={handleChangeForm}/>
                ))}
            </form>
        </Paper>
    );
}

export default Filter;