import {format} from "date-fns";

// time picker
export const createInitialTimeLocalValue = (value) => {
    if (value) {
        return new Date("2000-01-01T" + value);
    }
    return null;
}

export const createTimeGlobalValue = (value) => {
    if (value) {
        return format(value, 'HH:mm:ss');
    }
    return "";
}

// date picker
export const createInitialDateLocalValue = (value) => {
    if (value) {
        return new Date(value + "T00:00:00");
    }
    return null;
}

export const createDateGlobalValue = (value) => {
    if (value) {
        return format(value, 'yyyy-MM-dd');
    }
    return "";
}

// datetime picker
export const createInitialDateTimeLocalValue = (value) => {
    if (value) {
        return new Date(value);
    }
    return null;
}

export const createDateTimeGlobalValue = (value) => {
    if (value) {
        return format(value, 'yyyy-MM-dd')+ 'T' + format(value, 'HH:mm:ss');
    }
    return "";
}